
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ContainerJob, InputSearch, MarginContainer } from '@orientaction/commons';
import { JobLayout } from '@orientaction/components';
import { useHistory, useLocation } from 'react-router-dom';
import {useStyles} from './style'

const Job = (props: any) => {
  const { push } = useHistory();
  const classes = useStyles();

  return (
    <JobLayout isaddBackground={true}>
      <ContainerJob>
        <MarginContainer big={true}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid item={true}>
              <Typography variant="h2" style={{ marginBottom: '20px' }}>
                annonces
              </Typography>
            </Grid>
          </Grid>
        </MarginContainer>
      </ContainerJob>
    </JobLayout>
  );
};

export default Job;
