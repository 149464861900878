// eslint-disable-next-line simple-import-sort/imports
import { Page, TitlePage } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from '../../style';
import { imgBook4 } from '../../../../utils/constants';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page44 = () => {
  const { language } = useLanguage();

  const classe = useStyles();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_HARMONY2.png' : 'HARMONY2.png';
  }, [language]);

  return (
    <Page id={44}>
      <TitlePage>PASSEZ MAINTENANT LE TEST DES 16 SOFT SKILLS HARMONY®</TitlePage>

      <MarginContainer>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={12}>
            <div className={classe.cardImageBtn}>
              <img
                src={`${imgBook4}/${image}`}
                alt="couverture intro"
                className={classe.imageBtn}
              />
              <Link
                to={`${language === 'GB' ? `/en/tests/harmony` : `/tests/harmony`}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classe.btnInside}
                style={{ bottom: '15%' }}
              >
                Clique
              </Link>
            </div>
          </Grid>
        </Grid>
        {/* <Img
          filename="harmony.png"
          alt="harmony"
          style={{ width: '100%', height: 'auto', display: 'block', margin: 'auto' }}
        /> */}
      </MarginContainer>
      {/* <MarginContainer>
        <Paragrapth align="center" colorBlue={true} bold={true}>
          <i>Scannez ce code avec l’appareil photo de votre smartphone</i>
        </Paragrapth>
        <Paragrapth align="center" colorBlue={true} bold={true}>
          <i>et découvrez le test des « soft skills » ORIENTACTION</i>
        </Paragrapth>
      </MarginContainer>
      <Button
        variant="contained"
        className={classe.backgroundPrimaryBook}
        style={{ display: 'block', margin: 'auto' }}
        onClick={goToHarmonie}>
        Harmony
      </Button> */}
    </Page>
  );
};

export default Page44;
