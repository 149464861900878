export const list = [
  {
    label: 'RÉSOLUTION DE PROBLÈMES',
    name: 'resolveProblem',
  },
  {
    label: 'COMMUNICATION',
    name: 'Communication',
  },
  {
    label: 'CONFIANCE',
    name: 'CONFIANCE',
  },
  {
    label: 'INTELLIGENCE ÉMOTIONNELLE',
    name: 'InteligenceEmmotionnel',
  },
  {
    label: 'EMPATHIE',
    name: 'Empathie',
  },
  {
    label: 'GESTION DU TEMPS',
    name: 'gestionDuTemps',
  },
  {
    label: 'GESTION DU STRESS',
    name: 'gestionDuStress',
  },
  {
    label: 'CRÉATIVITÉ',
    name: 'creativite',
  },
  {
    label: 'ESPRIT D’ENTREPRENDRE',
    name: 'espritEntrepreneur',
  },
  {
    label: 'AUDACE',
    name: 'audace',
  },
  {
    label: 'AUTOMOTIVATION',
    name: 'automotivation',
  },
  {
    label: 'VISION',
    name: 'vision',
  },
  {
    label: 'PRÉSENCE',
    name: 'presence',
  },
  {
    label: 'SENS DU COLLECTIF',
    name: 'sensDuCollectif',
  },
  {
    label: 'CURIOSITÉ',
    name: 'curiosite',
  },
  {
    label: 'ESPRIT CRITIQUE',
    name: 'expritCritique',
  },
];

export const item = [
  {
    number: 1,
  },
  {
    number: 2,
  },
  {
    number: 3,
  },
  {
    number: 4,
  },
  {
    number: 5,
  },
];
