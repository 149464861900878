/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */

// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ColorSelection } from '@orientaction/commons';
import cloneDeep from 'lodash/cloneDeep';
import { useBook2 } from '@orientaction/hooks_book';

ChartJS.register(ArcElement);

const CamembertBesoin2 = (props: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(0);
  const { handleChange, dataColorNum, defaultColor } = props;
  const [dataCouleur, setDataCouleur] = useState<any>(null);
  const whiteValue = [
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
  ];
  useEffect(() => {
    setDataCouleur(defaultColor || whiteValue);
  }, [defaultColor]);

  const data = {
    datasets: [
      {
        label: '# of Votes',
        data: [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        backgroundColor: dataCouleur,
        borderColor: '#000A8C',
        borderWidth: 1,
      },
    ],
  };
  const changeCouleur = (couleur: any) => {
    const dataC = cloneDeep(dataCouleur);
    dataC[index] = couleur;
    setDataCouleur(dataC);
    handleChange(dataC, dataColorNum);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <Pie
        data={data}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            datalabels: {
              display: false,
            },
          },
        }}
        getElementAtEvent={(element, event) => {
          if (event.type === 'click') {
            handleOpenModal();
            setIndex(element[0].index);
          }
        }}
      />
      <ColorSelection
        open={openModal}
        callBack={(event: any) => setOpenModal(event)}
        colorChoosed={(color: any) => changeCouleur(color)}
      />
    </div>
  );
};

export default CamembertBesoin2;
