/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { hardSkills } from '@orientaction/utils';
import clsx from 'clsx';
import { TableAB2, Page, Paragrapth } from '@orientaction/components';
import { Button } from '@material-ui/core';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { useEffect, useState } from 'react';

const Page48 = (props: any) => {
  const classes = useStyles();
  const level = [
    'NE PARLE PAS DU TOUT',
    'DÉBUTANT',
    'INTERMÉDIAIRE',
    'AVANCÉ',
    'EXPERT',
    'LANGUE MATERNELLE',
  ];
  const valuesLangage = [
    {
      name: 'Francais',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Anglais',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Chinois',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Espagnol',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Allemand',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Portugais',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Arabe',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Japonais',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Russe',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
    {
      name: 'Hindi',
      level: [
        'NE PARLE PAS DU TOUT',
        'DÉBUTANT',
        'INTERMÉDIAIRE',
        'AVANCÉ',
        'EXPERT',
        'LANGUE MATERNELLE',
      ],
    },
  ];
  const ebookRecVal = useSelector(ebookRecValues);
  const { idEbook } = props;
  const [defaultChecked, setDefaultChecked] = useState<any>([]);
  useEffect(() => {
    if (ebookRecVal) {
      setDefaultChecked(ebookRecVal?.langage || {});
    }
  }, [ebookRecVal]);

  const handleChangeValues = async (dataToPut: any) => {
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        langage: dataToPut,
      },
    });
  };
  return (
    <Page id={48}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="h1"
          component="h1"
          color="primary"
          gutterBottom={true}
          className="opacity07">
          MAÎTRISE DES LANGUES ÉTRANGÈRES
        </Typography>
      </div>
      <div
        className={clsx(classes.trait, classes.positionCenter)}
        style={{ marginTop: 30, marginBottom: 30 }}
      />
      <Paragrapth align="center">
        Notez ici votre niveau dans ces différentes langues étrangères.{' '}
      </Paragrapth>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TableAB2 level={level} valuesLangage={valuesLangage} handleChangeValues={handleChangeValues} defaultChecked={defaultChecked} />
      </div>
      <div style={{ marginTop: 75 }}>
        <Paragrapth align='center' colorBlue={true}>
          <strong>TÉLÉCHARGEZ ICI UN CERTIFICAT SI VOUS EN DISPOSEZ D’UN</strong>
        </Paragrapth>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: '#2B56A5' }}
          className={classes.fixed}>
          <span style={{ color: 'white' }}>CLIQUEZ ICI POUR TÉLÉCHARGER</span>
        </Button>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    fixed: {
      display: 'block',
      margin: 'auto',
      marginTop: '10px',
      padding: 20,
      height: 'auto',
      width: '60%'
    },
  })
);

export default Page48;
