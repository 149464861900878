/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath, imgBook2} from '../../../../utils/constants';

const Page07 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={7}>
      <br />
      <Paragrapth align="justify">
        Si vous commencez tout juste ce travail de connaissance de soi, il faudra donc vous montrer
        assez prudent face aux résultats. Cette prudence ne doit pas devenir une source de
        frustration, car la connaissance de soi est un processus dynamique. Ce qui compte, c’est de
        commencer. Vous aurez ensuite tout le temps d’approfondir. Si vous avez le sentiment d’avoir
        cherché à donner une bonne image de vous-même quand vous avez répondu au test, n’hésitez pas
        à le repasser en essayant cette fois d’être le plus authentique possible.
      </Paragrapth>
      <img
        src={`${imgBook2}/illustration_page_39.jpg`}
        alt="photo"
        className={classe.imgNeedFull}
        style={{ margin: 'auto' }}
      />
    </Page>
  );
};
export default Page07;
