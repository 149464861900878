/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath, imgBook2 } from '../../../../utils/constants';
import Typography from '@material-ui/core/Typography';

const Page41 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={41} center={true}>
      <div className={classe.textCenterTop} style={{ marginTop: '20px', marginBottom: '20px' }}>
        <div className={classe.trait00} />
        <br />
        <Typography variant="h1" component="h1" style={{ fontSize: '45px', color: '#2b56a5' }}>
          <strong>étape 4</strong>
        </Typography>
        <br />
        <br />
        <Typography
          variant="h3"
          component="h3"
          style={{ fontSize: '45px', color: '#2b56a5' }}
          gutterBottom={true}
          className="opacity07">
          MIEUX CONNAÎTRE <br />
          VOS TALENTS <br />
          <span style={{ fontSize: '35px' }}>(SOFT SKILLS ET HARD SKILLS)</span>
        </Typography>
        <br />
        <div className={classe.trait00} />
      </div>
    </Page>
  );
};
export default Page41;
