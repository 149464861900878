/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page, TitleRomain, Paragrapth } from '@orientaction/components';
import { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useBook2 } from "@orientaction/hooks_book";
import { debounce } from 'lodash';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';

let typeTimeout: any;

const Page28 = (props: any) => {
  const classes = useStyles();
  const { idEbook } = props;
  const ebookRecVal = useSelector(ebookRecValues);
  const [response15, setResponse15] = useState<any>('');

  useEffect(() => {
    if (ebookRecVal) {
      setResponse15(ebookRecVal?.eternityNeed || '');
    }
  }, [ebookRecVal]);
  const updateValue = debounce((event) => {
    if (props.readOnly) return;
    handleChangeGlobalState(event);
  }, 1000);

  const onKeyDown = (event: any) => {
    if (props.readOnly) return;
    clearTimeout(typeTimeout);
    updateValue(event);
  };

  const handleChange = (e: any) => {
    if (props.readOnly) return;
    updateValue(e)
  };

  const handleChangeGlobalState = async (e: any) => {
    if (props.readOnly) return;
    const { value } = e.target;
    setResponse15(value);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        eternityNeed: value,
      },
    });
  };

  return (
    <Page
      id={28}
      readOnly={false}
    >
      <div style={{ border: "2px solid grey", padding: "15px" }}>
        <div className={classes.cardBgGrey}>
          <TitleRomain
            number="VI"
            text="VOS BESOINS «D’ÉTERNITÉ»"
          />
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr number={15} text="Écrivez dans le cadre ci-dessous toutes les choses que vous aimeriez avoir réalisées et qui seraient susceptibles de laisser une trace de vous sur cette terre :" isSmall={true} />
        </div>
        <br />
        <div className={classes.cardBgGrey}>
          <Typography>
            Je voudrais devenir :
          </Typography>
          <textarea
            name="name"
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onBlur={handleChangeGlobalState}
            defaultValue={response15}
            readOnly={props.readOnly}
            placeholder="Aa"
            style={{
              width: "99%",
              height: "150px",
              fontSize: '15px',
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
            }}
            className={classes.borderText}
          />
        </div>
        <div style={{ marginTop: '40px', textAlign: 'center' }}>
          <Paragrapth align='justify' colorBlue={true}>
            <strong>On peut laisser son empreinte de bien des manières :</strong>
          </Paragrapth>
        </div>
        <div style={{ marginTop: '40px', textAlign: 'justify' }}>
          <Paragrapth align='justify'>
            En ayant inventé un outil, écrit un livre, réalisé une œuvre artistique, transmis des
            connaissances, éduqué, réinséré des personnes en difficulté, en ayant créé une
            entreprise, en ayant des enfants qui ont réussi leur vie ou dans la vie, en ayant son
            nom écrit sur une plaque en tant que donateur(trice)….
          </Paragrapth>
        </div>
        <div style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'justify' }}>
          <Paragrapth align='justify'>
            Ce qui compte est, à mon sens, moins l’impression de grandeur que vous laisserez
            aux générations futures que l’intention d’avoir voulu leur léguer quelque chose
            d’utile, de bon et de durable.
          </Paragrapth>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    h3: {
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: 'black',
      fontStyle: 'italic',
      textAlign: 'center'
    },
    letter: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10%',
      padding: '7px',
    },
    borderText: {
      border: "2px solid #0E1247",
      padding: 5
    },
  })
);
export default Page28;
