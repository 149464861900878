/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook1 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleChapitre, Page } from '@orientaction/components';


const Page15 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={15}>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" color="primary" gutterBottom={true} className="opacity07">
            QUELLES SONT VOS VALEURS ?  <br />
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} />
        <div style={{ marginTop: '50px' }}>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', textAlign: 'justify', fontWeight: 'lighter' }}>
            La meilleure manière d’être heureux(se) est de mener une vie en cohérence avec ses
            valeurs. Bien connaître ses valeurs et vivre en accord avec elles permet de se réaliser
            pleinement. En identifiant vos valeurs, vous saurez en quoi vous croyez vraiment, ce
            que vous avez envie de défendre, ce sur quoi vous ne transigerez jamais.
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Connaître vos valeurs vous guidera vers les métiers ou les activités qui pourront vous
            permettre de vous épanouir, d’être en accord avec vous-même et de vous réaliser
            dans ce que vous faites.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '100px', marginBottom: '20px' }}>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={7}>
            <img className={classes.img} src={`${imgBook1}/question.png`} alt="logo" />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1000px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    }
  })
);
export default Page15;
