// eslint-disable-next-line simple-import-sort/imports
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@orientaction/commons';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import useStyles from './style';

interface IBodyScore {
  label: string;
  onChange: (data: any) => void;
  name: string;
  value: any;
  index: number;
}

const BodyScore: FC<IBodyScore> = ({ onChange, name, value, label, index }) => {
  const classes = useStyles();

  return (
    <div key={index} className={classes.borderTable}>
      <Grid container={true} direction="row" justifyContent="center" alignItems="center" key={name}>
        <Grid item={true} xs={9} className={classes.categorieA} style={{ textAlign: 'center' }}>
          <Typography
            variant="h2"
            component="h2"
            className={clsx(classes.h7)}
            style={{ color: 'black', textAlign: 'center' }}>
            {label || ''}
          </Typography>
        </Grid>

        <Grid item={true} xs={3} className={classes.categorieB}>
          <TextField
            onChange={onChange}
            name={name}
            value={value || ''}
            type="number"
            placeholder="Max : 100"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BodyScore;
