/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Button, Grid, Typography } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath, publicSvgPath } from '../../../../utils/constants';

const Page57 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={57}>
      <TitlePage>
        <b style={{ fontSize: 20 }}>
          VOUS VOULEZ ALLER PLUS LOIN DANS VOTRE QUESTIONNEMENT PROFESSIONNEL.
        </b>{' '}
        <br />
        <br />
        ET SI VOUS FAISIEZ UN BILAN DE COMPÉTENCES ?
      </TitlePage>

      <Grid container={true} style={{ marginTop: 40 }}>
        <Grid item={true} xs={12}>
          <img
            src={`${publicBookPath}/talk2.png`}
            alt="photo"
            className={classe.imgNeedFull}
            style={{ width: '65%', margin: 'auto' }}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: '40px', marginBottom: '20px' }}>
        <div style={{ color: '#2b56a5' }}>
          <div className={classe.flexPositionImg}>
            <img
              src={`${publicSvgPath}/point.png`}
              alt="Chapitre title"
              className={classe.imgFloat}
            />
            <Typography
              variant="h1"
              component="h1"
              style={{
                marginLeft: 10,
                marginBottom: '10px',
                color: '#2b56a5',
                fontSize: '20px',
                lineHeight: 1.4,
              }}>
              MIEUX CONNAÎTRE VOS VALEURS, VOS BESOINS, VOTRE PERSONNALITÉ ET VOS TALENTS.
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '10px', marginBottom: '20px' }}>
        <div style={{ color: '#2b56a5' }}>
          <div className={classe.flexPositionImg}>
            <img
              src={`${publicSvgPath}/point.png`}
              alt="Chapitre title"
              className={classe.imgFloat}
            />
            <Typography
              variant="h1"
              component="h1"
              style={{
                marginLeft: 10,
                marginBottom: '10px',
                color: '#2b56a5',
                fontSize: '20px',
                lineHeight: 1.4,
              }}>
              DÉFINIR UN PROJET PROFESSIONNEL SOLIDE ET COHÉRENT, QUI AMÉLIORE VOS CHANCES DE
              TROUVER L’ÉPANOUISSEMENT ET LA RÉUSSITE.
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '75px', marginBottom: '20px' }}>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: '#2B56A5' }}
          className={classe.fixedBtn}>
          <span style={{ color: 'white' }}>
            DEMANDER UN RENDEZ-VOUS GRATUIT <br /> ET SANS ENGAGEMENT
          </span>
        </Button>
      </div>
    </Page>
  );
};
export default Page57;
