/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, TitleRomain } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { apiServiceNative } from '../../../../utils/fetchNative';

const Page51 = (props: any) => {
  const classes = useStyles();

  const [responseCondition1_, setResponseCondition1_] = useState<any>({});
  const [responseCondition2_, setResponseCondition2_] = useState<any>({});
  const [responseCondition3_, setResponseCondition3_] = useState<any>({});
  const [responseCondition4_, setResponseCondition4_] = useState<any>({});
  const ebookRecVal = useSelector(ebookRecValues);
  const { idEbook } = props;
  useEffect(() => {
    if (ebookRecVal) {
      setResponseCondition1_(ebookRecVal?.responseCondition1 || {});
      setResponseCondition2_(ebookRecVal?.responseCondition2 || {});
      setResponseCondition3_(ebookRecVal?.responseCondition3 || {});
      setResponseCondition4_(ebookRecVal?.responseCondition4 || {});
    }
  }, [ebookRecVal]);
  const question1 = [
    {
      name: '',
      text: '1 jour de télétravail',
    },
    {
      name: '',
      text: '2 jours de télétravail',
    },
    {
      name: '',
      text: '3 jours de télétravail',
    },
    {
      name: '',
      text: '4 jour de télétravail',
    },
    {
      name: '',
      text: '5 jours de télétravail (Full remote)',
    },
  ];
  const question2 = [
    {
      name: '',
      text: '5 semaines de congés payés',
    },
    {
      name: '',
      text: '6 semaines de congés payés',
    },
    {
      name: '',
      text: '7 semaines de congés payés',
    },
    {
      name: '',
      text: '8 semaines de congés payés',
    },
    {
      name: '',
      text: '9 semaines de congés payés',
    },
  ];
  const question3 = [
    {
      name: '',
      text: 'Une semaine de 5 jours, c’est parfait pour vous',
    },
    {
      name: '',
      text: 'Dans l’idéal, vous préfèreriez une semaine de 4 jours',
    },
  ];
  const question4 = [
    {
      name: '',
      text: 'Moins de 10 minutes',
    },
    {
      name: '',
      text: '30 minutes maximum',
    },
    {
      name: '',
      text: 'Entre 10 et 20 minutes',
    },
    {
      name: '',
      text: '45 minutes maximum',
    },
    {
      name: '',
      text: '1h maximum',
    },
  ];

  const handleChange = async (e: any, index: number) => {
    const { name, checked } = e.target;
    const data = { [name]: checked }
    if (index === 1) {
      setResponseCondition1_(data);
    } else if (index === 2) {
      setResponseCondition2_(data);
    } else if (index === 3) {
      setResponseCondition3_(data);
    } else if (index === 4) {
      setResponseCondition4_(data);
    }
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        [`responseCondition${index}`]: data,
      },
    });
  };
  return (
    <Page id={51} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            CONDITIONS DE TRAVAIL <br />
            ET ÉQUILIBRES DE VIE
          </Typography>
        </div>
      </div>
      <div
        className={clsx(classes.trait, classes.positionCenter)}
        style={{ marginTop: '50px', marginBottom: '50px' }}
      />
      <div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            text="Concernant le télétravail et ce afin de pouvoir avoir un équilibre de vie professionnelle
            et personnelle qui vous convient parfaitement, vous souhaitez pour chaque semaine
            travaillée avoir :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question1.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 1)}
                            checked={!!responseCondition1_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            text="Au niveau du nombre de jours de congés annuels, vous souhaiteriez dans l’idéal
            et au minimum :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question2.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 2)}
                            checked={!!responseCondition2_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr text="Au niveau de votre organisation hebdomadaire :" isSmall={true} />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question3.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 3)}
                            checked={!!responseCondition3_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            text="Au niveau du temps de transport quotidien pour vous rendre au travail, ce que vous
            jugez acceptable :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question4.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 4)}
                            checked={!!responseCondition4_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page51;
