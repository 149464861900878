/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath } from '../../../../utils/constants';

const Page05 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={5}>
      <Paragrapth align="justify">
        Votre bonheur et votre satisfaction au travail sont au coeur de nos préoccupations. Nous
        aspirons à ce que vous trouviez un poste non seulement adapté à vos compétences, mais qui
        réponde aussi à vos aspirations et vous permette d’exprimer pleinement votre potentiel. En
        parallèle, nous nous efforçons de fournir aux entreprises des collaborateurs fidèles,
        loyaux, efficaces et performants, capables de contribuer de manière significative à leur
        succès.
      </Paragrapth>
      <br />
      <br />
      <br />
      <Paragrapth align="justify">
        Nous vous encourageons à aborder ce processus avec enthousiasme et ouverture d’esprit.
        Chaque étape est une opportunité d’en apprendre davantage sur vous-même et sur l’opportunité
        qui vous attend. Bienvenue dans cette aventure passionnante avec ORIENTACTION® EMPLOI.
        Ensemble, travaillons à façonner un avenir professionnel où vous vous sentirez pleinement
        accompli.
      </Paragrapth>
      <br />
      <br />
      <br />
      <Paragrapth align="justify">Respectueusement,</Paragrapth>
      <br />
      <br />
      <br />
      <Paragrapth align="right">Dr. Emeric LEBRETON & Branko RAJCEVIC</Paragrapth> <br />
      <Paragrapth align="right">
        <i>Président et directeur général d’ORIENTACTION® EMPLOI</i>
      </Paragrapth>
      <div className={classe.trait00} style={{ marginBottom: 25 }} />
      <div className={classe.containerCompass}>
        <Grid container={true} justifyContent="space-around">
          <Grid item={true} xs={12} sm={5}>
            <img src={`${publicBookPath}/photo1.png`} alt="photo" className={classe.imgNeedFull} />
          </Grid>
          <Grid item={true} xs={12} sm={5}>
            <img src={`${publicBookPath}/photo2.png`} alt="photo" className={classe.imgNeedFull} />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};
export default Page05;
