import { generate } from 'generate-password-browser';
import { useContext, useState } from 'react';
import { UserFormContext } from '../components/Form/CreateEditAdmin/Context/UserFormContext';

const useRandomPassword = () => {
  const [isRandomPasswordCopied, setIsRandomPasswordCopied] = useState<boolean>(false);
  const { updateFormUser, formUser } = useContext<any>(UserFormContext);

  const { generatedPassword } = formUser;

  const generateRandomPassword = async () => {
    const password = generate({
      length: 10,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: true,
      exclude: '$',
      strict: true,
    });
    updateFormUser({ ...formUser, generatedPassword: password });
  };

  const copyRandomPassword = async () => {
    navigator.clipboard.writeText(generatedPassword);
    setIsRandomPasswordCopied(true);
  };

  return {
    randomPassword: generatedPassword,
    isRandomPasswordCopied,
    generateRandomPassword,
    copyRandomPassword,
  };
};

export default useRandomPassword;
