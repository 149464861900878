/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line simple-import-sort/imports
import Page00 from './Pages/00';
import Page01 from './Pages/01';
import Page02 from './Pages/02';
import Page03 from './Pages/03';
import Page04 from './Pages/04';
import Page05 from './Pages/05';
import Page06 from './Pages/06';
import Page07 from './Pages/07';
import Page08 from './Pages/08';
import Page09 from './Pages/09';
import Page10 from './Pages/10';
import Page11 from './Pages/11';
import Page12 from './Pages/12';
import Page13 from './Pages/13';
import Page14 from './Pages/14';
import Page15 from './Pages/15';
import Page16 from './Pages/16';
import Page17 from './Pages/17';
import Page18 from './Pages/18';
import Page19 from './Pages/19';
import Page20 from './Pages/20';
import Page21 from './Pages/21';
import Page22 from './Pages/22';
import Page23 from './Pages/23';
import Page24 from './Pages/24';
import Page25 from './Pages/25';
import Page26 from './Pages/26';
import Page27 from './Pages/27';
import Page28 from './Pages/28';
import Page29 from './Pages/29';
import Page30 from './Pages/30';
import Page31 from './Pages/31';
import Page32 from './Pages/32';
import Page33 from './Pages/33';
import Page34 from './Pages/34';
import Page35 from './Pages/35';
import Page36 from './Pages/36';
import Page37 from './Pages/37';
import Page38 from './Pages/38';
import Page39 from './Pages/39';
import Page40 from './Pages/40';
import Page41 from './Pages/41';
import Page42 from './Pages/42';
import Page43 from './Pages/43';
import Page44 from './Pages/44';
import Page45 from './Pages/45';
import Page46 from './Pages/46';
import Page47 from './Pages/47';
import Page48 from './Pages/48';
import Page49 from './Pages/49';
import Page50 from './Pages/50';
import Page51 from './Pages/51';
import Page52 from './Pages/52';
import Page53 from './Pages/53';
import Page54 from './Pages/54';
import Page55 from './Pages/55';
import Page56 from './Pages/56';
import Page57 from './Pages/57';
import { ButtonSomaire, FooterEbook, PageCouverture } from '@orientaction/components';
import { useTheme } from '@material-ui/core/styles';
import { LayoutResultat } from '@orientaction/layouts';
import useStyles from './style';
import { useBookIntro } from '@orientaction/hooks_book';
import { useEffect, useState } from 'react';
import { useAlert, useRedirectToRootPage } from '@orientaction/hooks';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { useLocation, useParams } from 'react-router-dom';
import { isAdminVerify } from '@orientaction/utils';
import { apiServiceNative } from '../../../utils/fetchNative';
import { ebookRecAction } from '@orientaction/api-actions';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';



const EbookRecrutement = (props: WithWidth) => {
  const theme = useTheme();

  const alert = useAlert();

  const location = useLocation();

  const { goToRootPage } = useRedirectToRootPage();

  const [readOnly, setReadonly] = useState(true);
  const [loading, setLoading] = useState(false);

  const [users_permissions_user, setUsers_permissions_user] = useState(0);

  const { id, index }: any = useParams();

  const classe = useStyles();

  const { width } = props;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const ebookRecVal = useSelector(ebookRecValues);
  const dispatch = useDispatch();

  useEffect(() => {
    const getDataEbook = async () => {
      setLoading(true)
      const response = await apiServiceNative.get(`/api/ebook-recrutements/${id}`);
      console.log({response});
      dispatch(ebookRecAction.setAllField(response.data.attributes));
      setLoading(false)
    };
    getDataEbook();
  }, [id])

  return (
    <>
      <LayoutResultat
        isWhite={true}
        showLogo={false}
        toBack={
          isAdminVerify()
            ? `/admin/ressources-beneficiaire/${users_permissions_user}`
            : '/mes-ressources'
        }
        index={index}>
        {loading ? (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
            }}>
            <p />
            Chargement en cours...
          </div>
        ) : (
          <div className={classe.readOnly}>
            {/* <PageCouverture src="couverture0.jpg" /> */}
            <Page00 />
            <Page01 />
            <Page02 />
            <Page03 />
            <Page04 />
            <Page05 />
            <Page06 />
            <Page07 />
            <Page08 />
            <Page09 idEbook={id} />
            <Page10 />
            <Page11 />
            <Page12 />
            <Page13 />
            <Page14 idEbook={id} />
            <Page15 />
            <Page16 />
            <Page17 />
            <Page18 />
            <Page19 />
            <Page20 />
            <Page21 idEbook={id} />
            <Page22 />
            <Page23 idEbook={id} />
            <Page24 />
            <Page25 idEbook={id} />
            <Page26 idEbook={id} />
            <Page27 idEbook={id} />
            <Page28 idEbook={id} />
            <Page29 idEbook={id} />
            <Page30 />
            <Page31 />
            <Page32 />
            <Page33 />
            <Page34 idEbook={id} />
            <Page35 idEbook={id} />
            <Page36 />
            <Page37 />
            <Page38 />
            <Page39 idEbook={id} />
            <Page40 />
            <Page41 />
            <Page42 />
            <Page43 />
            <Page44 />
            <Page45 idEbook={id} />
            <Page46 idEbook={id} />
            <Page47 idEbook={id} />
            <Page48 idEbook={id} />
            <Page49 />
            <Page50 />
            <Page51 idEbook={id} />
            <Page52 idEbook={id} />
            <Page53 />
            <Page54 idEbook={id} />
            <Page55 />
            <Page56 />
            <Page57 />
            {/* <div className={classe.marginFooter}>
              <FooterEbook
                ebookName="l’Introduction"
                toBack={
                  isAdminVerify()
                    ? `/admin/ressources-beneficiaire/${users_permissions_user}`
                    : '/mes-ressources'
                }
              />
            </div> */}
            <ButtonSomaire href="page-3" />
          </div>
        )}
      </LayoutResultat>
    </>
  );
};

export default withWidth()(EbookRecrutement);
