/* eslint-disable react/no-array-index-key */
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import { useBook4 } from '@orientaction/hooks_book';
import { FC, useEffect, useState } from 'react';
import useStyle from './style';
import { list, item } from './constant';
import { cloneDeep } from 'lodash';

const SelectItem = (props: any) => {
  const { handleChangeItem, defaultSelect } = props;

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const dataSelect = cloneDeep(defaultSelect);
    dataSelect[index] = value;
    handleChangeItem(dataSelect, value, index);
  };

  const classe = useStyle();

  return (
    <>
      {item.map((oneItem: any, index: number) => (
        <div>
          <Grid container={true} direction="row" className={classe.margin}>
            <Grid item={true} xs={2} md={1}>
              <span className={classe.span}>
                <strong>{oneItem.number}</strong>
              </span>
            </Grid>

            <Grid item={true} xs={10} md={11}>
              <FormControl className={classe.formControl}>
                <Select
                  name={oneItem.number}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={defaultSelect[index] || ''}
                  onChange={(e) => handleChange(e, index)}>
                  {list.map((itemList: any, ind: number) => (
                    <MenuItem key={`${ind}-${itemList}`} value={itemList.label}>{itemList.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  );
};

export default SelectItem;
