// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect, useState } from 'react';
import { cloneDeep, findIndex, filter } from 'lodash';
import { useBook2 } from '@orientaction/hooks_book';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding: 20,
    '@media only screen and (max-width: 600px)': {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: 12,
      fontWeight: 800,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: 10,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 10,
      fontSize: 12,
      fontWeight: 800,
    },
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#eaeff9',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
  cardBgGrey: {
    background: '#E6E6E6',
  },
  borderTable: {
    borderRadius: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important',
  },
  img: {
    width: '40%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '80%',
    },
  },
  paragraphe: {
    fontSize: '14px',
    color: '#2c57a5',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left !important',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left !important',
    },
  },
  floatIndex: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `url(${imgBook1}/back.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      bottom: '5px',
      left: '5px',
    },
  },
});

const TableAB2 = (props: any) => {
  const classes = useStyles();
  const { level, valuesLangage, handleChangeValues, defaultChecked } = props;
  const [values, setValues] = useState<any>({});

  useEffect(() => {
    if (defaultChecked) {
      setValues(defaultChecked);
    }
  }, [defaultChecked]);

  const handleChange = (value: any, name: string) => {
    console.log({ name, value });
    const data = {
      ...values,
      [name]: value,
    };
    setValues(data);
    handleChangeValues(data);
  };

  return (
    <Card className={classes.borderTable}>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <div className={classes.borderTable}>
            {level && (
              <Grid container={true}>
                <Grid
                  container={true}
                  item={true}
                  xs={3}
                  style={{ textAlign: 'center', border: '0.5px solid #2b56a5' }}
                />
                <Grid
                  container={true}
                  item={true}
                  xs={9}
                  style={{ textAlign: 'center', border: '0.5px solid #2b56a5' }}>
                  {level.map((elem: string) => (
                    <Grid
                      item={true}
                      xs={2}
                      style={{ textAlign: 'center', border: '0.5px solid #2b56a5' }}
                      className={classes.categorieA}>
                      <Typography
                        variant="h2"
                        component="h2"
                        style={{ fontSize: 10 }}
                        className={clsx(classes.h7)}>
                        {elem}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {valuesLangage?.map((langage: any, index: number) => (
              <Grid container={true} style={{ borderTop: '1px solid black' }}>
                <Grid
                  container={true}
                  item={true}
                  xs={3}
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className={classes.categorieA}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                    {langage.name}
                  </Typography>
                </Grid>
                <Grid
                  container={true}
                  item={true}
                  xs={9}
                  style={{ textAlign: 'center' }}
                  className={classes.categorieA}>
                  {langage.level.map((elem: string) => (
                    <Grid
                      item={true}
                      xs={2}
                      style={{
                        position: 'relative',
                        textAlign: 'center',
                        border: '0.5px solid #2b56a5',
                      }}>
                      <Checkbox
                        color="primary"
                        name={elem}
                        onChange={(e: any) => handleChange(e.target.name, langage.name)}
                        checked={values[langage.name] === elem}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TableAB2;
