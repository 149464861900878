// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { publicSvgPath, imgBook1 } from '../../utils/constants';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 800,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#0E1247',
    display: 'block',
    style: 'normal',
    alignItems: 'center',
    padding: 5,
  },
  categorieB: {
    color: '#fff !important',
    background: '#2c57a5',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
  cardBgGrey: {
    background: '#e8eef4',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  borderTable: {
    borderRadius: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important',
  },
  floatIndex: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: `url(${imgBook1}/back2.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '45px',
    height: '45px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      top: '5px',
      right: '5px',
    },
  },
});

const ItemAspiration = (props: any) => {
  const classes = useStyles();
  const { aspiration, handleChange, valuesCheked } = props;

  return (
    <div>
      <Grid container={true}>
        <Grid
          item={true}
          xs={12}
          style={{ position: 'relative', textAlign: 'center', borderBottom: '1px solid black' }}
          className={classes.cardBgGrey}>
          <Typography component="p" className={classes.h7}>
            {aspiration.type}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} style={{ borderTop: '1px solid black'}}>
        <Grid item={true} xs={12} style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
          {aspiration.metiers.map((elem: any) => (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={elem}
                  onChange={(e: any) => handleChange(e.target.name)}
                  checked={valuesCheked?.includes(elem)}
                />
              }
              label={<div style={{ color: 'black' }}>{elem}</div>}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
export default ItemAspiration;
