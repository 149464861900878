/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from '@orientaction/components';
import { useBook2 } from "@orientaction/hooks_book";
import clsx from 'clsx';
import { cloneDeep, difference, findIndex, debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { imgBook2, publicSvgPath } from '../../../../utils/constants';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';

let typeTimeout: any;
const Page35 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  const column1 = [
    {
      name: '',
      text: 'Agressif(ve)'
    },
    {
      name: '',
      text: 'Anxieux(se)',
    },
    {
      name: '',
      text: 'Arriviste',
    },
    {
      name: '',
      text: 'Autoritaire',
    },
    {
      name: '',
      text: 'Avare',
    },
    {
      name: '',
      text: 'Bavard(e)',
    },
    {
      name: '',
      text: 'Brouillé(e) avec les chiffres',
    },
    {
      name: '',
      text: 'Coléreux(se)',
    },
    {
      name: '',
      text: 'Manque de confiance en soi',
    },
    {
      name: '',
      text: 'Ne fait pas confiance aux autres',
    },
    {
      name: '',
      text: 'Dépressif(ve)',
    },
    {
      name: '',
      text: 'Désordonné(e)',
    },
    {
      name: '',
      text: 'Difficile/mauvais caractère',
    },
    {
      name: '',
      text: 'Effacé(e)',
    },
    {
      name: '',
      text: 'Égoïste',
    },
    {
      name: '',
      text: 'Exigeant(e)',
    },
    {
      name: '',
      text: 'Fermé(e)',
    },
    {
      name: '',
      text: 'Idéaliste',
    },
    {
      name: '',
      text: 'Impatient(e)',
    },
    {
      name: '',
      text: 'Imprécis(e)',
    },
  ];
  const column2 = [
    {
      name: '',
      text: 'Indécis(e)'
    },
    {
      name: '',
      text: 'Indiscret(e)',
    },
    {
      name: '',
      text: 'Instable',
    },
    {
      name: '',
      text: 'Irréfléchi(e)',
    },
    {
      name: '',
      text: 'Irresponsable',
    },
    {
      name: '',
      text: 'Lent(e)',
    },
    {
      name: '',
      text: 'Manque d’imagination',
    },
    {
      name: '',
      text: 'Mauvaise écoute',
    },
    {
      name: '',
      text: 'Ne donne pas suite',
    },
    {
      name: '',
      text: 'Ne prend pas l’initiative',
    },
    {
      name: '',
      text: 'Paresseux(se)',
    },
    {
      name: '',
      text: 'Pessimiste/négatif(ve)',
    },
    {
      name: '',
      text: 'Peureux(se)',
    },
    {
      name: '',
      text: 'Réservé(e)',
    },
    {
      name: '',
      text: 'Rigide',
    },
    {
      name: '',
      text: 'S’exprime mal',
    },
    {
      name: '',
      text: 'Secret(e)',
    },
    {
      name: '',
      text: 'Solitaire',
    },
    {
      name: '',
      text: 'Timide',
    },
  ];
  const { idEbook } = props;
  const ebookRecVal = useSelector(ebookRecValues);
  const [dataConcat, setDataConcat] = useState<any>([]);
  const [textSelected1, setTextSelected1] = useState<any>([]);
  const [textSelected2, setTextSelected2] = useState<any>([]);
  const [textSelected3, setTextSelected3] = useState<any>([]);
  const [select, setSelect] = useState<any>([]);
  const [progressionText, setProgressionText] = useState<any>([]);
  const nameAttr = ['progression1', 'progression2', 'progression3'];
  useEffect(() => {
    if (ebookRecVal) {
      const groupAdjectifs = [
        ebookRecVal?.progression1 || '',
        ebookRecVal?.progression2 || '',
        ebookRecVal?.progression3 || '',
      ];
      setSelect(groupAdjectifs);
      setDataConcat(ebookRecVal?.progressionAxe ? JSON.parse(ebookRecVal?.progressionAxe || "") : []);
      setProgressionText(ebookRecVal?.progressionText ? JSON.parse(ebookRecVal?.progressionText || "") : [])
    }
  }, [ebookRecVal]);

  const removeFromIndex = (array: any, index: number) => {
    return array.map((elem: any, i: number) => {
      let el = elem;
      if (index === i) {
        el = '';
      }
      return el;
    });
  };
  const verifyDataInArray = (data: any, index: number) => {
    if (index === 0) {
      setTextSelected2(difference(dataConcat, removeFromIndex(data, 1)));
      setTextSelected3(difference(dataConcat, removeFromIndex(data, 2)));
    } else if (index === 1) {
      setTextSelected1(difference(dataConcat, removeFromIndex(data, 0)));
      setTextSelected3(difference(dataConcat, removeFromIndex(data, 2)));
    } else if (index === 2) {
      setTextSelected1(difference(dataConcat, removeFromIndex(data, 0)));
      setTextSelected2(difference(dataConcat, removeFromIndex(data, 1)));
    }
  }
  useEffect(() => {
    const data = cloneDeep(select);
    setTextSelected1(difference(dataConcat, removeFromIndex(data, 0)));
    setTextSelected2(difference(dataConcat, removeFromIndex(data, 1)));
    setTextSelected3(difference(dataConcat, removeFromIndex(data, 2)));
  }, [dataConcat]);

  const handleChangeSelect = async (e: any, index: number) => {
    const data = cloneDeep(select);
    data[index] = e.target.value;
    verifyDataInArray(data, index);
    setSelect(data);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        [nameAttr[index]]: e.target.value,
      },
    });
  };
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.readOnly) return;
    const found = findIndex(dataConcat, function (o) { return o === (event.target as HTMLInputElement).name })
    let data = cloneDeep(dataConcat)
    if (found === -1) {
      data = [...data, (event.target as HTMLInputElement).name];
    } else {
      data.splice(found, 1);
    }
    setDataConcat(data);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        progressionAxe: JSON.stringify(data),
      },
    });
  };
  const updateValue = debounce((event, index) => {
    handleChangeGlobalState(event, index);
  }, 1000)

  const onKeyDown = (event: any, index: number) => {
    updateValue(event, index);
  };

  const handleChangeText = (e: any, index: number) => {
    updateValue(event, index);
  };

  const handleChangeGlobalState = async (e: any, index: number) => {
    if (props.readOnly) return;
    const { value } = e.target;
    const data = cloneDeep(progressionText);
    data[index] = value;
    setProgressionText(data);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        progressionText: JSON.stringify(data),
      },
    });
  };
  return (
    <Page id={35} readOnly={false}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
          VOS AXES DE PROGRESSION
        </Typography>
        <div className={classes.cardBgGrey} style={{ marginTop: '40px' }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} sm={6} xs={12} className={classes.fieldset}>
              <FormControl component="fieldset" style={{ display: "block !important" }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                  {column1.map((elem: any) => {
                    return (<FormControlLabel
                      control={<Checkbox
                        readOnly={props.readOnly}
                        color="primary"
                        name={elem.text}
                        onChange={handleChange}
                        checked={dataConcat.indexOf(elem.text) === -1 ? false : true}
                        style={{ padding: 3 }}
                      />}
                      label={
                        <div>
                          {elem.text}
                        </div>
                      }
                      labelPlacement="start"
                      style={{ justifyContent: "space-between" }}
                      color="primary"
                    />)
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} xs={12} className={classes.fieldset}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                  {column2.map((elem: any) => {
                    return (<FormControlLabel
                      control={<Checkbox
                        readOnly={props.readOnly}
                        color="primary"
                        name={elem.text}
                        onChange={handleChange}
                        checked={dataConcat.indexOf(elem.text) === -1 ? false : true}
                        style={{ padding: 3 }}
                      />}
                      label={
                        <div>
                          {elem.text}
                        </div>
                      }
                      labelPlacement="start"
                      style={{ justifyContent: "space-between" }}
                      color="primary"
                    />)
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 50, textAlign: 'center' }}>
          <Typography className={classes.testimony} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', }}>
              Reportez ci-dessous les trois qualités que vous aimeriez développer :
          </Typography>
        </div>
        <div style={{ marginTop: 40 }}>
          <div>
            <Grid container={true} >
              <Grid item={true} xs={12} sm={3} className={classes.h6}>
                <Typography>
                  Axe de progression n°1 :
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={9}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={select[0] || ''}
                    onChange={(e: any) => handleChangeSelect(e, 0)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty={true}
                    readOnly={props.readOnly}
                  >
                    <MenuItem value="" disabled={true}>
                      Cliquer ici
                    </MenuItem>
                    {textSelected1?.map((col: any) => {
                      return (
                        <MenuItem value={col} key={col}>
                          {col}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ marginTop: 20, textAlign: 'left', color: 'black' }}>
              <Typography>
                Ce que vous allez faire pour vous améliorer :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChangeText(e, 0)}
                onKeyDown={(e: any) => onKeyDown(e, 0)}
                onBlur={(e: any) => handleChangeGlobalState(e, 0)}
                defaultValue={progressionText[0]}
                readOnly={props.readOnly}
                placeholder="Aa"
                style={{
                  width: "99%",
                  height: "100px",
                  fontSize: '15px',
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: 10,
                }}
                className={classes.borderText}
              />
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <Grid container={true} >
              <Grid item={true} xs={12} sm={3} className={classes.h6}>
                <Typography>
                  Axe de progression n°2 :
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={9}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={select[1] || ''}
                    onChange={(e: any) => handleChangeSelect(e, 1)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty={true}
                    readOnly={props.readOnly}
                  >
                    <MenuItem value="" disabled={true}>
                      Cliquer ici
                    </MenuItem>
                    {textSelected2?.map((col: any) => {
                      return (
                        <MenuItem value={col} key={col}>
                          {col}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ marginTop: 20, textAlign: 'left', color: 'black' }}>
              <Typography>
                Ce que vous allez faire pour vous améliorer :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChangeText(e, 1)}
                onKeyDown={(e: any) => onKeyDown(e, 1)}
                onBlur={(e: any) => handleChangeGlobalState(e, 1)}
                defaultValue={progressionText[1]}
                readOnly={props.readOnly}


                placeholder="Aa"
                style={{
                  width: "99%",
                  height: "100px",
                  fontSize: '15px',
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: 10,
                }}
                className={classes.borderText}
              />
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <Grid container={true} >
              <Grid item={true} xs={12} sm={3} className={classes.h6}>
                <Typography>
                  Axe de progression n°3 :
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={9}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={select[2] || ''}
                    onChange={(e: any) => handleChangeSelect(e, 2)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty={true}
                    readOnly={props.readOnly}
                  >
                    <MenuItem value="" disabled={true}>
                      Cliquer ici
                    </MenuItem>
                    {textSelected3?.map((col: any) => {
                      return (
                        <MenuItem value={col} key={col}>
                          {col}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ marginTop: 20, textAlign: 'left', color: 'black' }}>
              <Typography>
                Ce que vous allez faire pour vous améliorer :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChangeText(e, 2)}
                onKeyDown={(e: any) => onKeyDown(e, 2)}
                onBlur={(e: any) => handleChangeGlobalState(e, 2)}
                defaultValue={progressionText[2]}
                readOnly={props.readOnly}
                placeholder="Aa"
                style={{
                  width: "99%",
                  height: "100px",
                  fontSize: '15px',
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: 10,
                }}
                className={classes.borderText}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      marginRight: '-85px',
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'right',
    },
    imgTailleur: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    imgFurious: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
      },
    },
    imgFurious1: {
      width: '30%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none'
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
      color: 'black'
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 5,
      paddingRight: 15,
      fontSize: 12,
      background: '#e8eef4',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    fieldset: {
      "& fieldset": {
        display: "block !important"
      },
    },
    h6: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      color: '#0E1247',
      style: 'normal',
      textAlign: 'left',
      alignItems: 'center',
      display: 'flex'
    },
    borderText: {
      border: "2px solid #2b56a5",
      padding: 5
    },
  })
);
export default Page35;
