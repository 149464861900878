/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page } from '@orientaction/components';
import { useMemo, useState } from 'react';
import { useLanguage } from '@orientaction/hooks';
import { List, ListItem, ListItemText } from '@material-ui/core';

const Page53 = (props: any) => {
  const classes = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'illustration_page_30.png' : 'illustration_page_30.png';
  }, [language]);
  return (
    <Page id={53}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            VOS BESOINS <br />
            ET VOS DOMAINES DE VIE
          </Typography>
        </div>
        <div
          className={clsx(classes.trait, classes.positionCenter)}
          style={{ marginTop: '50px', marginBottom: '50px' }}
        />
        <div>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Cet exercice constitue une base de réflexion sur l’organisation de votre temps en
            fonction des priorités que vous attribuez à chaque domaine de votre vie.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Chaque cercle est composé de 24 quartiers représentant les 24 heures d’une journée, vous
            permettant ainsi de vous repérer facilement.
          </Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <TitleNbr
            number={1}
            text="Coloriez le premier cercle en fonction du temps moyen que vous consacrez à chaque domaine de vie."
            isSmall={true}
            isItalic={true}
          />
          <br />
          <TitleNbr
            number={2}
            text="Dans le deuxième cercle, représentez la situation qui serait idéale pour vous."
            isSmall={true}
            isItalic={true}
          />
          <br />
          <TitleNbr
            number={3}
            text="Faites de même pour l’organisation de vos week-ends."
            isSmall={true}
            isItalic={true}
          />
        </div>
        <div style={{ marginTop: '40px' }}>
          <Typography
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'justify',
              fontWeight: 'bold',
              color: '#2b56a5',
            }}>
            <strong>EXEMPLE :</strong>
          </Typography>
          <div style={{ border: '1px solid #8080801f' }}>
            <div style={{ padding: '15px 10px 20px' }}>
              <Grid container={true} justifyContent="space-between" style={{ paddingBottom: '10px' }}>
                <Grid item={true} xs={5}>
                  <Typography className={classes.textCircle}>
                    Ce que je vis aujourd'hui :{' '}
                  </Typography>
                </Grid>
                <Grid item={true} xs={5}>
                  <Typography className={classes.textCircle}>
                    Ce qui me permettrait d'être plus serein(e)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container={true} justifyContent="center">
                <Grid item={true} xs={12}>
                  <img
                    className={classes.img}
                    src={`${imgBook2}/illustration_circles_only.png`}
                    alt="logo"
                  />
                </Grid>
              </Grid>
            </div>
            <Grid
              container={true}
              style={{ padding: '10px 20px 10px', borderTop: '1px solid #8080801f' }}>
              <Grid item={true} xs={12} sm={6}>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem className={classes.itemNoPadding}>
                    <ListItemText primary="Professionnel : " className={classes.itemList} />
                    <span
                      className={classes.trait2}
                      style={{ background: '#2e96d3', marginLeft: '1px' }}
                    />
                  </ListItem>
                  <ListItem className={classes.itemNoPadding}>
                    <ListItemText primary="Familial : " className={classes.itemList} />
                    <span className={classes.trait2} style={{ background: '#e2e318' }} />
                  </ListItem>
                  <ListItem className={classes.itemNoPadding}>
                    <ListItemText primary="Couple : " className={classes.itemList} />
                    <span className={classes.trait2} style={{ background: '#11af4b' }} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem className={classes.itemNoPadding}>
                    <ListItemText primary="Social : " className={classes.itemList} />
                    <span className={classes.trait2} style={{ background: '#f2655b' }} />
                  </ListItem>
                  <ListItem className={classes.itemNoPadding}>
                    <ListItemText primary="Intime : " className={classes.itemList} />
                    <span className={classes.trait2} style={{ background: '#ed1748' }} />
                  </ListItem>
                  <ListItem className={classes.itemNoPadding}>
                    <ListItemText primary="Sommeil : " className={classes.itemList} />
                    <span className={classes.trait2} style={{ background: '#939598' }} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe2: {
      color: '#000A8C',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    h3: {
      fontSize: '25px',
      display: 'flex',
      width: 'calc(100% - 20px)',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#000A8C',
      fontStyle: 'bold',
      textAlign: 'left',
    },
    textCircle: {
      textAlign: 'center',
      fontWeight: 500,
      color: '#2b56a5',
    },
    itemList: {
      fontSize: 15,
      color: '#2b56a5',
      '& span': {
        fontWeight: 500,
      },
    },
    trait2: {
      width: '70px',
      height: '20px',
    },
    itemNoPadding: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
  })
);
export default Page53;
