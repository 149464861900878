// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { ListParagrapth, Paragrapth, Page, Img } from '@orientaction/components';
import useStyles from '../../style';

const Page02 = (props: any) => {
  const classe = useStyles();

  return (
    <Page id={2} center={true}>
      <div className={classe.titleSteveJobs}>
        <i>
          « Vous pouvez imaginer, créer et construire l’endroit le plus merveilleux de la terre,
          mais il faudra toujours des hommes et des femmes pour que le rêve devienne réalité. »
        </i>
      </div>

      <div
        style={{
          width: '7%',
          height: 10,
          backgroundColor: 'yellow',
          margin: '5vh auto',
          color: 'yellow',
        }}>
        -
      </div>

      <div
        style={{
          textAlign: 'center',
        }}>
        <Paragrapth colorBlue={true} align="center">
          Walt Disney
        </Paragrapth>
      </div>

      <Img
        src="illustration_page_15.jpg"
        alt="Steve Jobs"
        style={{
          width: '70%',
        }}
      />
    </Page>
  );
};
export default Page02;
