/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TableOrganisation } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import { cloneDeep, difference } from 'lodash';
import { useEffect, useState } from 'react';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';

const Page18 = (props: any) => {
  const classes = useStyles();
  const texts = [
    'Innovante',
    'Où l’on prend en compte le bien-être des gens',
    'Qui a une forte utilité sociale',
    'Où l’on progresse au mérite',
    'Dans laquelle on se fait des ami(e)s',
    'Orientée vers les besoins des clients',
    'Où l’on se forme souvent et où l’on apprend un métier',
    'Connue et reconnue dans votre région',
    'Où les relations humaines sont primordiales',
    'Historique et sans risque de disparition',
    'Où les rémunérations sont élevées',
    'Prestigieuse',
    'Ouverte à l’international',
    'Conquérante',
    'Où l’on progresse en fonction de son niveau d’expertise',
    'Dirigée par un(e) leader(euse) charismatique',
    'Dans laquelle on peut gravir les échelons',
    'Qui offre un emploi pour toute la vie',
    'Où l’on peut rencontrer son mari (sa femme)',
    'En fort développement',
    'Qui veut changer le monde ou la société',
    'Où les relations avec son(sa) manager(euse) sont proches',
    'Dans laquelle on recherche l’excellence',
    'Où l’on progresse grâce à son niveau de diplôme',
    'Qui permet de laisser sa trace dans l’histoire',
    'Où l’on progresse grâce à la politique',
    'Qui est admirée pour ses succès et ses réalisations',
    'Où l’on se montre indulgent(e) en cas d’erreur',
    'Où l’on apprécie la prise de risque et l’audace',
    'Dans laquelle il existe de nombreuses opportunités',
    'Où l’on apprécie plutôt le conformisme',
    'Où il existe de nombreux avantages comme un CE',
    'Puissante',
    'Qui recrute seulement les meilleur(e)s',
    'Dont les projets font rêver',
    'Dans laquelle, on se sent protégé(e)',
  ];

  const { idEbook } = props;
  const ebookRecVal = useSelector(ebookRecValues);
  const [textSelected, setTextSelected] = useState<any>([]);
  const [textSelected1, setTextSelected1] = useState<any>([]);
  const [textSelected2, setTextSelected2] = useState<any>([]);
  const [textSelected3, setTextSelected3] = useState<any>([]);
  const [select, setSelect] = useState<any>([]);
  const nameAttr = ['firstAdjectif', 'secondAdjectif', 'thirdAdjectif'];

  useEffect(() => {
    if (ebookRecVal) {
      const groupAdjectifs = [
        ebookRecVal?.firstAdjectif || '',
        ebookRecVal?.secondAdjectif || '',
        ebookRecVal?.thirdAdjectif || '',
      ];
      setSelect(groupAdjectifs);
      setTextSelected(ebookRecVal?.adjectifs ? ebookRecVal?.adjectifs : []);
    }
  }, [ebookRecVal]);

  const removeFromIndex = (array: any, index: number) => {
    return array.map((elem: any, i: number) => {
      let el = elem;
      if (index === i) {
        el = '';
      }
      return el;
    });
  };
  const handleChangeSelect = async (e: any, index: number) => {
    const data = cloneDeep(select);
    data[index] = e.target.value;
    verifyDataInArray(data, index);
    setSelect(data);
    console.log({ data });

    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        [nameAttr[index]]: e.target.value,
      },
    });
    console.log({ response });
  };
  const handleChange = async (data: any) => {
    setTextSelected(data);
    console.log({ data });
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        adjectifs: data,
      },
    });
    console.log({ response });
    // setAdjectifs(JSON.stringify(data));
  };
  const verifyDataInArray = (data: any, index: number) => {
    if (index === 0) {
      setTextSelected2(difference(textSelected, removeFromIndex(data, 1)));
      setTextSelected3(difference(textSelected, removeFromIndex(data, 2)));
    } else if (index === 1) {
      setTextSelected1(difference(textSelected, removeFromIndex(data, 0)));
      setTextSelected3(difference(textSelected, removeFromIndex(data, 2)));
    } else if (index === 2) {
      setTextSelected1(difference(textSelected, removeFromIndex(data, 0)));
      setTextSelected2(difference(textSelected, removeFromIndex(data, 1)));
    }
  };
  useEffect(() => {
    const data = cloneDeep(select);
    setTextSelected1(difference(textSelected, removeFromIndex(data, 0)));
    setTextSelected2(difference(textSelected, removeFromIndex(data, 1)));
    setTextSelected3(difference(textSelected, removeFromIndex(data, 2)));
  }, [textSelected]);
  // useEffect(() => {
  //   setTextSelected(adjectifs);
  // }, [adjectifs]);
  return (
    <Page id={23} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            CHOISISSEZ MAINTENANT <br />
            L’ORGANISATION CAPABLE DE <br />
            RÉPONDRE À VOS BESOINS !
          </Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '50px',
              marginBottom: '50px',
              textAlign: 'center',
              fontWeight: 'lighter',
            }}>
            Sélectionnez les adjectifs ou les expressions présents (dix au maximum) dans ce tableau
            et qui correspondraient à votre organisation idéale.
          </Typography>
        </div>
      </div>
      <TableOrganisation texts={texts} onChange={handleChange} defaultPropsValue={textSelected} />
      <div>
        <div style={{ marginTop: '50px' }}>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '50px',
              marginBottom: '50px',
              textAlign: 'center',
              fontWeight: 'lighter',
            }}>
            Sélectionnez maintenant parmi ceux que vous avez entouré, les trois adjectifs ou
            expressions qui correspondent le plus à votre organisation idéale.
          </Typography>
        </div>
        <Grid container={true} spacing={2}>
          <Grid key={1} item={true} xs={12} sm={4}>
            <FormControl key={1} variant="outlined" style={{ width: '100%' }}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                key={1}
                value={select[0] || ''}
                onChange={(e: any) => handleChangeSelect(e, 0)}
                placeholder="Entreprise"
                style={{ borderRadius: '10px', border: '1px solid #2b56a5' }}
                displayEmpty={true}>
                <MenuItem value="" disabled={true} key="void">
                  Cliquer ici
                </MenuItem>
                {textSelected1.length > 0 &&
                  textSelected1?.map((text: any) => {
                    return (
                      <MenuItem value={text} key={text}>
                        {text}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid key={2} item={true} xs={12} sm={4}>
            <FormControl key={2} variant="outlined" style={{ width: '100%' }}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                key={2}
                value={select[1] || ''}
                onChange={(e: any) => handleChangeSelect(e, 1)}
                placeholder="Entreprise"
                style={{ borderRadius: '10px', border: '1px solid #2b56a5' }}
                displayEmpty={true}>
                <MenuItem value="" disabled={true} key="void">
                  Cliquer ici
                </MenuItem>
                {textSelected2.length > 0 &&
                  textSelected2?.map((text: any) => {
                    return (
                      <MenuItem value={text} key={text}>
                        {text}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid key={3} item={true} xs={12} sm={4}>
            <FormControl key={3} variant="outlined" style={{ width: '100%' }}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                key={3}
                value={select[2] || ''}
                onChange={(e: any) => handleChangeSelect(e, 2)}
                placeholder="Entreprise"
                style={{ borderRadius: '10px', border: '1px solid #2b56a5' }}
                displayEmpty={true}>
                <MenuItem value="" disabled={true} key="void">
                  Cliquer ici
                </MenuItem>
                {textSelected3.length > 0 &&
                  textSelected3?.map((text: any) => {
                    return (
                      <MenuItem value={text} key={text}>
                        {text}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page18;
