/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TableQuestionReponse } from '@orientaction/components';

import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { cloneDeep, debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { apiServiceNative } from '../../../../utils/fetchNative';

const Page9 = (props: any) => {
  const classes = useStyles();
  const ebookRecVal = useSelector(ebookRecValues);
  const [dataToPut, setDataToPut] = useState<any>({});

  const { idEbook } = props;

  const updateValue = debounce(event => {
    handleChangeGlobalState(event);
  }, 1000);

  const onKeyDown = (event: any) => {
    updateValue(event);
  };

  const handleChange = (e: any) => {
    if (props.readOnly) return;
    updateValue(e);
  };

  const handleChangeGlobalState = async (e: any) => {
    if (props.readOnly) return;
    const { value, name } = e.target;

    const cloneData = cloneDeep(dataToPut);

    const dataTransformed = {
      ...cloneData,
      [`Q${name}`]: value,
    };
    setDataToPut(dataTransformed);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: dataTransformed,
    });
    console.log({response});
    console.log({dataTransformed});
  };

  const defaultData = useMemo(() => {
    return {
      Answer_1: ebookRecVal.QAnswer_1,
      Answer_2: ebookRecVal.QAnswer_2,
      Answer_3: ebookRecVal.QAnswer_3,
      Answer_4: ebookRecVal.QAnswer_4,
      Answer_5: ebookRecVal.QAnswer_5,
      Answer_6: ebookRecVal.QAnswer_6,
      Answer_7: ebookRecVal.QAnswer_7,
    }
  }, [ebookRecVal]);

  return (
    <Page id={9} readOnly={false}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="h1"
          component="h1"
          color="primary"
          gutterBottom={true}
          className={classes.title}>
          CONCRÈTEMENT,QUELLES SONT <br />
          VOS ASPIRATIONS PERSONNELLES ET <br />
          PROFESSIONNELLES ?
        </Typography>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} />
      <div style={{ marginTop: '50px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '50px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Essayez de vous projeter dans l’avenir pour vous construire un horizon.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Ces questions vont vous y aider. Il est normal de ne pas réussir à répondre à toutes ces
          questions immédiatement.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Ce qui compte, c’est de commencer à y réfléchir. Les réponses viendront par la suite.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Votre consultant(e) va vous aider à construire votre projet professionnel et personnel à
          court, moyen et long terme.
        </Typography>
      </div>

      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TableQuestionReponse
          handleChange={handleChange}
          keyDown={onKeyDown}
          handleChangeGlobalState={handleChangeGlobalState}
          defaultValue={defaultData}
          readonly={props.readOnly}
          listquestion={[
            {
              label: 'Dans cinq ans, que voudriez-vous être ?',
              name: 'Answer_1',
            },
            {
              label: 'Dans cinq ans, que voudriez-vous ne pas être ?',
              name: 'Answer_2',
            },
            {
              label: 'Que regrettez-vous de ne pas avoir fait ?',
              name: 'Answer_3',
            },
            {
              label: 'Que n’avez-vous plus envie de faire ?',
              name: 'Answer_4',
            },
            {
              label: 'Que voulez-vous continuer à faire ?',
              name: 'Answer_7', // Rajout
            },
            {
              label: 'Si vous disposiez de trois heures de plus par jour, que feriez-vous ?',
              name: 'Answer_5',
            },
            {
              label: 'Que rêveriez-vous de faire ?',
              name: 'Answer_6',
            },
          ]}
        />
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    title: {
      '@media only screen and (max-width: 600px)': {
        fontSize: '20px',
      },
    },
  })
);
export default Page9;
