import Board from './Board';
import Candidates from './Candidates';
import Job from './Job';
import NewJob from './NewJob';

export default {
  Board,
  Candidates,
  Job,
  NewJob,
};
