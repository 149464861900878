/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { TitleNbr, Page } from '@orientaction/components';

const Page38 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={38}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            TEST : TEAM PERSO
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: "50px", marginBottom: "50px" }} />
        <div>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'justify', fontWeight: 'lighter' }}>
            Voici la signification résumée de chacun des neuf rôles en équipe. Chacun d’entre
            nous va souvent avoir deux ou trois rôles préférés, deux ou trois rôles possibles, deux
            ou trois rôles à éviter.
          </Typography>
          <br />
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'justify', fontWeight: 'lighter' }}>
            Dans les analyses de profil d’un individu, c’est la combinaison de ces rôles qui
            sera étudiée, les subtilités sont donc très nombreuses et ne peuvent se réduire à
            cette simple description. Il faut noter également que nous pouvons successivement
            occuper des rôles différents en fonction de l’équipe que nous intégrons.
          </Typography>
          <br />
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'justify', fontWeight: 'lighter' }}>
            Les contributions principales de chaque rôle sont les suivantes :
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px', }}>
        <TitleNbr
          number={1}
          text="LE CONCEPTEUR (LA CONCEPTRICE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Créatif(ve), imaginatif(ve), peu orthodoxe et surtout anticonformiste, le Concepteur
          (la Conceptrice) propose de nouvelles idées et des solutions créatives.
          Le Concepteur (la Conceptrice) résout les problèmes les plus complexes.
        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={2}
          text="LE STRATÈGE (LA STRATÈGE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Modéré(e), stratégique, le Stratège (la Stratège) fait preuve de discernement et
          envisage toutes les options. Le Stratège (la Stratège) étudie avec précision et de
          manière objective la réalisation possible des idées et leur intérêt pratique.
        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={3}
          text="L’EXPERT (L’EXPERTE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Déterminé(e), autonome, concentré(e) vers un seul objectif, l’Expert (l’Experte)
          possède une source de connaissances et de compétences techniques peu
          communes, utiles à l’équipe. L’Expert (l’Experte) est un(e) spécialiste dans un
          domaine pointu.
        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={4}
          text="L’ORGANISATEUR (L’ORGANISATRICE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Discipliné(e), fiable, ordonné(e), méthodique et efficace, l’Organisateur
          (l’Organisatrice) transforme les grandes idées en actions concrètes et pratiques,
          que les membres de l’équipe pourront réaliser.

        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={5}
          text="LE PROPULSEUR (LA PROPULSEUSE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Dynamique et fonceur(se), le(a) Propulseur(se) travaille bien sous pression et aime
          les défis. Il (Elle) a le courage et la capacité de surmonter les obstacles. Il (Elle)
          pousse les autres à l’action et aide l’équipe à se recentrer sur son travail.
        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={6}
          text="LE PEAUFINEUR (LA PEAUFINEUSE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Consciencieux(se), à la recherche des erreurs et des omissions, le Peaufineur (la
          Peaufineuse) a le souci de la perfection. Le Peaufineur (la Peaufineuse) s’assure
          que le travail est bien fini et fait en sorte que le programme et les délais soient
          respectés.
        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={7}
          text="LE COORDINATEUR (LA COORDINATRICE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Mature, confiant(e), attentif(ve) aux autres et diplomate, le Coordinateur (la
          Coordinatrice) clarifie les objectifs et fait progresser les prises de décision.
          Le Coordinateur (la Coordinatrice) s’assure que les efforts et les qualités des
          coéquipiers soient employés au mieux.
        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={8}
          text="LE PROMOTEUR (LA PROMOTRICE)"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Extraverti(e), enthousiaste et communicatif(ve), le Promoteur (la Promotrice)
          explore les opportunités et développe les contacts, à l’intérieur comme à l’extérieur
          de l’équipe et de l’organisation. Le Promoteur (la Promotrice) met en valeur les
          réalisations de l’équipe.
        </Typography>
      </div>
      <div style={{ marginTop: '20px', }}>
        <TitleNbr
          number={9}
          text="LE SOUTIEN"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '15px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Sociable, coopératif(ve), posé(e), sensible et conciliant(e), le Soutien est attentif(ve)
          aux autres et à leurs besoins. Plein(e) de tact, à l’écoute, il(elle) évite les frictions et
          recherche le consensus. Il(Elle) contribue à bâtir le relationnel de l’équipe.
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6'
    }
  })
);
export default Page38;
