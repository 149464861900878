const INITIAL_STATE: any = {
  values: {
    // values
    softSkills: [],
  },
};

const ebookRec = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'UPDATE_FIELD_EBOOK_REC':
      return {
        ...state,
        values: { ...state.values, [action.payload.field]: action.payload.value },
      };
    case 'SET_ALL_FIELD':
      return {
        ...state,
        values: action.payload,
      };

    default:
      return state;
  }
};

export default ebookRec;
