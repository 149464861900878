/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath } from '../../../../utils/constants';

const Page55 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={55}>
      <TitlePage>VOUS VENEZ DE CANDIDATER À UNE OFFRE D’ORIENTACTION® EMPLOI</TitlePage>
      <br />
      <Paragrapth align="justify">Cher(e) candidat(e),</Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Alors que nous arrivons au terme de ce parcours de recrutement, nous tenons à vous remercier
        sincèrement pour le temps et l’engagement que vous avez consacrés à compléter les exercices
        et tests de cet ebook. Votre participation active est un pas important dans la quête de
        votre épanouissement professionnel.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Nous entrons maintenant dans une phase cruciale : l’analyse de vos résultats. Notre équipe
        de recruteurs, assistée par une intelligence artificielle avancée, procédera au «Matching»
        de votre profil avec le poste pour lequel vous avez candidaté. Cette approche innovante nous
        permet d’assurer une correspondance précise et équitable entre vos compétences, vos
        aspirations et les exigences du poste.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Nous vous invitons à consulter la plate-forme E-Orientaction, accessible via l’onglet «Ma
        boussole», où vous pourrez retrouver tous les résultats de vos tests. Cette transparence
        vise à vous offrir une vision claire de votre profil et de son adéquation avec les
        opportunités professionnelles.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Si votre candidature n’est pas retenue pour ce poste spécifique, veuillez ne pas le
        percevoir comme un échec. Il se peut simplement qu’un autre profil ait mieux correspondu aux
        compétences spécifiques ou aux aspirations du poste en question. Cependant, votre profil
        restera dans notre base de données et sera considéré pour de futures opportunités qui
        pourraient mieux correspondre à vos talents uniques et à votre chemin professionnel.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Nous restons à votre disposition pour toute question ou besoin supplémentaire.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">Respectueusement,</Paragrapth>
      <br />
      <br />
      <Paragrapth align="right">Dr. Emeric LEBRETON & Branko RAJCEVIC</Paragrapth> <br />
      <Paragrapth align="right">
        <i>Président et directeur général d’ORIENTACTION® EMPLOI</i>
      </Paragrapth>
    </Page>
  );
};
export default Page55;
