/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import { Page, TitlePage, TitleNbr } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import ArrayScore from '../../../EbookEtape_4/components/ArrayScore';
import { list, item } from './constant';
import List from './item';
import { useBook4 } from '@orientaction/hooks_book';
import { useEffect, useState } from 'react';
import { ebookRecAction } from '@orientaction/api-actions';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { apiServiceNative } from '../../../../../utils/fetchNative';

const Page34 = (props: any) => {
  const [softSkill, setSoftSkill] = useState<any>(null);
  const [defaultSoftSkill, setDefaultSoftSkill] = useState<any>(null);
  const [select, setSelect] = useState<any>({});
  const ebookRecVal = useSelector(ebookRecValues);
  const { idEbook } = props;
  const dispatch = useDispatch();
  const nameAttr = ['softSkill1', 'softSkill2', 'softSkill3', 'softSkill4', 'softSkill5'];

  useEffect(() => {
    if (ebookRecVal) {
      const groupAdjectifs = [
        ebookRecVal?.softSkill1 || '',
        ebookRecVal?.softSkill2 || '',
        ebookRecVal?.softSkill3 || '',
        ebookRecVal?.softSkill4 || '',
        ebookRecVal?.softSkill5 || '',
      ];
      setSelect(groupAdjectifs);
      setDefaultSoftSkill(ebookRecVal?.softSkill ? ebookRecVal?.softSkill : null);
      setSoftSkill(ebookRecVal?.softSkill ? ebookRecVal?.softSkill : null);
      console.log({softSkill: ebookRecVal?.softSkill ? ebookRecVal?.softSkill : {}})
    }
  }, [ebookRecVal]);

  const handleChange = async (e: any) => {
    const { name, value } = e.target;

    const softSkills = {
      ...softSkill,
      [name]: value,
    };

    setSoftSkill(softSkills);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        softSkill: softSkills,
      },
    });
  };
  const handleChangeItem = async (dataItem: any, value: string, index: number) => {
    console.log({ dataItem, value, index });
    dispatch(ebookRecAction.updateField(nameAttr[index], value));
    setSelect(dataItem);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        [nameAttr[index]]: value,
      },
    });
  };

  return (
    <Page id={45} readOnly={props.readOnly}>
      <TitlePage>
        IDENTIFIEZ ICI VOS
        <br />« SOFT SKILLS » DOMINANTES
      </TitlePage>

      <MarginContainer>
        <TitleNbr number={1} text="Notez les scores obtenus au test dans ce tableau." />
      </MarginContainer>
      <ArrayScore body={list} current={defaultSoftSkill} onChange={handleChange} />

      <MarginContainer>
        <TitleNbr
          number={2}
          text="Au-delà du test, choisissez les cinq « soft skills »
          qui vous caractérisent le mieux :"
        />
      </MarginContainer>

      <MarginContainer>
        <List handleChangeItem={handleChangeItem} defaultSelect={select} />
      </MarginContainer>
    </Page>
  );
};

export default Page34;
