// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, Page, Title } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
      color: '#2b56a5',
      fontFamily: 'ITC Avant Garde Gothic Std bold',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontSize: 22,
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'left',
    },
    imgTronc: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 20,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  })
);

const Page42 = () => {
  const classes = useStyles();

  return (
    <Page id={42}>
      <Title
        title="« SOFT SKILLS » ET « HARD SKILLS »"
        firstTitle="‘‘ On ne peut pas résoudre un problème avec le même niveau
        de pensée que celle qui l’a créé. ’’"
        firtsecondTitle="Albert Einstein"
        secondTitle="‘‘L'empathie est comme donner à quelqu'un un manteau psychique pour le protéger du froid.’’"
        secondSousTitle="Clarissa Pinkola Estés"
      />
      <MarginContainer>
        <Paragrapth align="justify">
          Les « soft skills » ou « compétences douces » sont des compétences comportementales par
          opposition aux « hard skills » qui sont des compétences techniques. Les « soft skills »
          s’acquièrent par l’expérience, mais elles peuvent également s’acquérir par le
          développement personnel, alors que les « hard skills » s’acquièrent par la formation.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Dans un contexte d’une économie de plus en plus automatisée, les « soft skills » jouent un
          rôle de plus en plus prégnant dans la réussite professionnelle des salarié(e)s et dans
          l’efficacité et la performance des entreprises.
        </Paragrapth>
      </MarginContainer>
      <div className={classes.cardBgGrey}>
        <Typography className={classes.textCenterTop} gutterBottom={true}>
          LA MÉDECINE
        </Typography>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            Un(e) étudiant(e) en médecine apprendra à diagnostiquer un cancer dans le cadre de ses
            études universitaires, mais il (elle) aura également à apprendre à communiquer le
            diagnostic de ce cancer à son (sa) patient(e) dans le cadre de son activité
            professionnelle.
          </Paragrapth>
        </MarginContainer>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            Diagnostiquer un cancer est une compétence technique qui dépend d’un savoir et d’une
            aptitude à analyser des symptômes et les résultats de différents examens.
          </Paragrapth>
        </MarginContainer>
        <Paragrapth align="justify" addBackground={true}>
          Choisir les bons mots pour communiquer ce diagnostic au (à la) patient(e) et/ou à sa
          famille est une compétence qui s’acquiert rarement dans le cadre d’études
          universitaires.
        </Paragrapth>
      </div>
      <MarginContainer>
        <Paragrapth align="justify">
          Les « soft skills » sont complémentaires aux compétences techniques, sans pour autant s’y
          substituer. Autre différence : alors que les « hard skills » sont des compétences très
          contextualisées (maîtrise d’une machine, d’un logiciel, etc.), les « soft skills »
          apparaissent comme des qualités personnelles indépendantes du contexte (capacité à
          apprendre, à rechercher des informations, à créer de la confiance, etc.).
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Par ailleurs, ces compétences se manifestent autant dans la vie professionnelle que dans
          la vie personnelle et ce, quel que soit le contexte et/ou le poste occupé.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth colorGrey={true} align="justify">
          <sup>2</sup> Sur ce point, je tiens à préciser que si les « soft skills » s’acquièrent
          principalement dans le cadre de l’expérience, c’est aussi parce que les instituts de
          formation ne les considèrent pas encore comme des compétences clefs. Il est probable que
          cette situation évolue dans les années à venir et que les instituts de formation proposent
          à leurs étudiant(e)s des programmes de développement des « soft skills ».
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};

export default Page42;
