/* eslint-disable no-use-before-define */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { figure32_page1 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TableDestin, Page } from '@orientaction/components';

const Page10 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={10}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" color="primary" gutterBottom={true} className="opacity07">
          LES 32 FIGURES DU DESTIN <br />
        </Typography>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} />
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TableDestin head={true} figure32={figure32_page1} />
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
  })
);
export default Page10;
