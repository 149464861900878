/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath } from '../../../../utils/constants';

const Page06 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={4}>
      <TitlePage>
        CE À QUOI VOUS DEVEZ ÊTRE ATTENTIF <br />
        AVANT DE RÉPONDRE AUX TESTS <br />
        ET AUX QUESTIONS
      </TitlePage>
      <br />
      <Paragrapth align="justify">
        Tout d’abord, il convient de faire attention aux différents biais qui peuvent vous amener à
        un résultat qui ne serait pas conforme à la réalité. Décrocher un poste sur la base de
        résultats faussés, c’est avoir une vision à court terme. Vous ne souhaitez pas obtenir un
        poste, j’imagine, qui ne vous permettra pas de vous épanouir et de vous mettre en échec.
        Voilà pourquoi il est important d’aborder les exercices, les questions et les tests qui vont
        suivre avec un maximum d’authenticité.
      </Paragrapth>
      <br />
      <br />
      <br />
      <div>
        <h4 className={classe.titleSeconde}>LA DÉSIRABILITÉ SOCIALE</h4>
        <br />
        <Paragrapth align="justify">
          Certaines personnes, notamment dans le cadre d’un recrutement, veulent absolument donner
          une bonne image d’elles-mêmes. Elles éprouvent des difficultés à montrer authentiques.
          Pour illustrer cette tendance, je prends souvent l’exemple du train. Quand on demande à
          ces personnes si elles préfèrent, lors d’un voyage en train, parler spontanément avec
          leurs voisins ou demeurer silencieuses, elles répondent qu’elles préfèrent converser avec
          leurs voisins, alors qu’en réalité elles ne le font quasiment jamais.
        </Paragrapth>
        <br />
        <br />
        <Paragrapth align="justify">
          Elles répondent ainsi pour donner l’image d’une personne ouverte et sociable, ce qui est
          perçu positivement dans notre société, alors que ce n’est pas leur préférence.
          Spontanément et naturellement, elles vont plutôt profiter de ce moment de calme et de
          solitude pour réfléchir, lire ou travailler sur leur ordinateur, ce qui leur procurera un
          sentiment de satisfaction et leur permettra de se ressourcer. Pour connaître sa vraie
          personnalité, il faut être capable de voir qui l’on est vraiment, même si cela n’est pas
          valorisé socialement.
        </Paragrapth>
      </div>
      <br />
      <br />
      <br />
      <div>
        <h4 className={classe.titleSeconde}>LA MÉCONNAISSANCE DE SOI</h4>
        <br />
        <Paragrapth align="justify">
          D’autres personnes ont une faible connaissance d’elles-mêmes. Elles ont passé tellement de
          temps à s’adapter aux autres et à leur environnement qu’elles ont fini par oublier qui
          elles étaient vraiment. Ces personnes ne savent plus ce que cela signifie que d’agir et de
          penser sans faire d’efforts. Ces personnes, même en répondant sincèrement aux questions du
          test, risquent de révéler 8 des préférences qui ne sont pas les leurs. On dit de ces
          personnes qu’elles ne révèlent pas leur personnalité profonde, mais leur personnalité
          adaptée.
        </Paragrapth>
      </div>
    </Page>
  );
};
export default Page06;
