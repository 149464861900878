import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginSomaire: {
      margin: '4vh 0',
      [theme.breakpoints.down('sm')]: {
        margin: '1vh 0',
      },
    },
    listSomaine: {
      fontSize: 20,
      color: '#2B56A5',
      textDecoration: 'none',
      '& span': {
        fontFamily: 'ITC Avant Garde Gothic Std Bold !important',
      },
    },
    textItalique: {
      margin: '1vh 0',
      fontFamily: 'calibri',
      fontSize: 15,
      color: '#2B56A5',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    },
    title: {
      fontSize: 35,
      color: '#2B56A5',
      fontWeight: 300,
      margin: '0 auto',
    },
    trait: {
      width: '7%',
      height: 10,
      backgroundColor: 'yellow',
      margin: '1vh 0',
      color: 'yellow',
      [theme.breakpoints.down('sm')]: {
        width: '12%',
      },
    },
    number: {
      fontFamily: 'calibri',
      fontSize: 20,
      color: '#2B56A5',
      textDecoration: 'none',
      textAlign: 'center',
    },
    containerSom: {
      margin: '10px 0px ',
    },
    itemStep: {
      fontSize: 35,
    },
  })
);

export default useStyles;
