/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, CamembertBesoin2 } from '@orientaction/components';
import { imgBook2 } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { apiServiceNative } from '../../../../utils/fetchNative';

const Page54 = (props: any) => {
  const classes = useStyles();
  const [dataColor1, setDataColor1] = useState<any>(null);
  const [dataColor11, setDataColor11] = useState<any>(null);
  const [dataColor2, setDataColor2] = useState<any>(null);
  const [dataColor21, setDataColor21] = useState<any>(null);

  const ebookRecVal = useSelector(ebookRecValues);
  const { idEbook } = props;
  useEffect(() => {
    if (ebookRecVal) {
      setDataColor1(ebookRecVal?.dataColor1 ? JSON.parse(ebookRecVal?.dataColor1 || "") : null);
      setDataColor11(ebookRecVal?.dataColor11 ? JSON.parse(ebookRecVal?.dataColor11 || "") : null);
      setDataColor2(ebookRecVal?.dataColor2 ? JSON.parse(ebookRecVal?.dataColor2 || "") : null);
      setDataColor21(ebookRecVal?.dataColor21 ? JSON.parse(ebookRecVal?.dataColor21 || "") : null);
    }
  }, [ebookRecVal]);

  const handleChangeDataColor = async (val: any, type: number) => {
    if (type === 1) {
      setDataColor1(val);
    } else if (type === 11) {
      setDataColor11(val);
    } else if (type === 2) {
      setDataColor2(val);
    } else if (type === 21) {
      setDataColor21(val);
    }
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        [`dataColor${type}`]: JSON.stringify(val),
      },
    });
  };

  return (
    <Page id={54} readOnly={props.readOnly}>
      <div style={{ border: '2px solid grey' }}>
        <div className={classes.cardBgGrey}>
          <Typography
            variant="h3"
            component="h3"
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'center',
              fontWeight: 'lighter',
            }}>
            <strong className={classes.h2}>VOS BESOINS ET VOS DOMAINES DE VIE EN SEMAINE</strong>
          </Typography>
        </div>
        <div style={{ padding: 15, paddingTop: 50 }}>
          <Grid container={true} spacing={4}>
            <Grid item={true} sm={5} xs={12}>
              <Typography
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontWeight: 'lighter',
                  marginBottom: 30,
                }}>
                Ce que je vis aujourd’hui :
              </Typography>
              <CamembertBesoin2
                defaultColor={dataColor1}
                dataColorNum={1}
                handleChange={handleChangeDataColor}
              />
            </Grid>
            <Grid item={true} sm={2} xs={12} className={classes.flexPosition}>
              <img className={classes.img} src={`${imgBook2}/arrow.png`} alt="logo" />
            </Grid>
            <Grid item={true} sm={5} xs={12}>
              <Typography
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontWeight: 'lighter',
                }}>
                Ce qui me permettrait
                <br />
                d’être plus serein(e)
              </Typography>
              <CamembertBesoin2
                defaultColor={dataColor11}
                dataColorNum={11}
                handleChange={handleChangeDataColor}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.cardBgGrey} style={{ marginTop: 50 }}>
          <Typography
            variant="h3"
            component="h3"
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'center',
              fontWeight: 'lighter',
            }}>
            <strong className={classes.h2}>VOS BESOINS ET VOS DOMAINES DE VIE EN WEEK-END</strong>
          </Typography>
        </div>
        <div style={{ padding: 15, paddingTop: 50 }}>
          <Grid container={true} spacing={4}>
            <Grid item={true} sm={5} xs={12}>
              <Typography
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontWeight: 'lighter',
                  marginBottom: 30,
                }}>
                Ce que je vis aujourd’hui :
              </Typography>
              <CamembertBesoin2
                defaultColor={dataColor2}
                dataColorNum={2}
                handleChange={handleChangeDataColor}
              />
            </Grid>
            <Grid item={true} sm={2} xs={12} className={classes.flexPosition}>
              <img className={classes.img} src={`${imgBook2}/arrow.png`} alt="logo" />
            </Grid>
            <Grid item={true} sm={5} xs={12}>
              <Typography
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontWeight: 'lighter',
                }}>
                Ce qui me permettrait
                <br />
                d’être plus serein(e)
              </Typography>
              <CamembertBesoin2
                defaultColor={dataColor21}
                dataColorNum={21}
                handleChange={handleChangeDataColor}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '80%',
      height: 'auto',
      '@media only screen and (max-width: 600px)': {
        transform: 'rotate(90deg)',
        width: '50%',
      },
      '@media only screen and (max-width: 361px)': {
        transform: 'rotate(90deg)',
        width: '50%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    h2: {
      fontSize: '25px',
      textAlign: 'center',
      color: '#2b56a5',
      fontWeight: 'bold',
    },
  })
);
export default Page54;
