/* eslint-disable no-use-before-define */
import { figure32_page2 } from '../../../../utils/constants';
import { TableDestin, Page } from '@orientaction/components';

const Page11 = (props: any) => {
  return (
    <Page id={11}>
      <TableDestin head={false} figure32={figure32_page2} />
    </Page>
  );
};

export default Page11;
