import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { publicSvgPath } from '@orientaction/utils';

export default makeStyles((theme: Theme) => ({
  grayBG: {
    background: '#EDEEF2 0% 0% no-repeat padding-box',
  },
  noPadding: {
    '&>div': {
      '@media(min-width: 1250px)': {
        padding: '0px !important',
      },
    },
  },
  imageBG: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern.png) !important`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern_mobile.png)  !important`,
      backgroundPositionY: 0,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern_mobile.png)  !important`,
      backgroundPositionY: 0,
    },
  },
  imageBG2: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern2.png) !important`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern_mobile.png)  !important`,
      backgroundPositionY: 0,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern_mobile.png)  !important`,
      backgroundPositionY: 0,
    },
  },
  imageBG3: {
    background: `url(${publicSvgPath}/fond_homepage.png), #0E1444`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPosition: '0% 0%',
    '@media only screen and (max-width: 600px)': {
      background: `url(${publicSvgPath}/fond_transparent_mobile_accueil.png), #0E1444`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundPosition: '0% 0%',
    },
    '@media only screen and (max-width: 361px)': {
      background: `url(${publicSvgPath}/fond_transparent_mobile_accueil.png), #0E1444`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundPosition: '0% 0%',
    },
  },
  imageClassic: {
    background: '#0E1444',
  },
  layout: {
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    width: '100%',
  },
  content: {
    flex: 1,
    overflow: 'hidden',
    '&>div': {
      color: theme.palette.primary.main,
      overflowY: 'auto',
    },
  },
  appBar: {
    paddingRight: theme.spacing(3),
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    /* border: '1px solid #E3E8F0', */
    border: 'none',
    '&>button': {
      marginRight: theme.spacing(-2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
      height: '60px',
    },
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& a': {
      marginRight: theme.spacing(4.75),
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '98.5%',
      margin: '20 auto',
    },
    '@media(min-width: 1250px)': {
      height: 'auto',
    },
  },
  logoContainer: {
    cursor: 'pointer',
    maxWidth: 200,
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 160,
      marginTop: 5,
      marginLeft: 10,
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  logoImg: {
    width: '100%',
    height: 'auto',
  },
  profilContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > div, hr': {
      '@media(min-width: 1250px)': {
        marginLeft: 25,
        marginTop: 3,
      },
      '@media only screen and (max-width: 600px)': {
        transform: 'translate3d(0px, 41px, 0px) !important',
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'translate3d(0px, 41px, 0px) !important',
        width: '100%',
      },
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  burgerMenu: {
    '& div, hr': {
      marginLeft: '0px !important',
      '@media(min-width: 1250px)': {
        marginLeft: '0px !important',
      },
    },
  },
  btnPrimary: {
    minHeight: 38,
    height: 38,
    width: 38,
    minWidth: 38,
    textTransform: 'none',
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    '@media(min-width: 600px)': {
      minHeight: 48,
      height: 48,
      width: 146,
      minWidth: 146,
    },
  },
  initial: {
    width: 38,
    height: 38,
    background: '#000A8C 0% 0% no-repeat padding-box',
    borderRadius: '100%',
    color: '#fff',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      height: '51%',
    },
  },
  divider: {
    display: 'none',
    '@media(min-width: 1250px)': {
      display: 'block',
      height: '20px',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
  textHeader: {
    display: 'none',
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 !important',
    cursor: 'pointer',
    '& > span': {
      marginLeft: 10,
      '@media(min-width: 1250px)': {
        marginLeft: 30,
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  btnWhite: {
    backgroundColor: '#fff#',
    color: '#0E1247',
    marginTop: 0,
    marginRight: 5,
    borderRadius: 3,
    opacity: 1,
    border: 'solid grey',
    textTransform: 'none',
    minHeight: 48,
    height: 48,
    width: 146,
    minWidth: 146,
    [theme.breakpoints.down('sm')]: {
      padding: '1vh',
      minHeight: 48,
      height: 48,
      width: 140,
      minWidth: 130,
    },
  },
  largeBtnWhite: {
    backgroundColor: '#fff#',
    color: '#0E1247',
    marginTop: 5,
    marginRight: 5,
    borderRadius: 3,
    opacity: 1,
    border: 'solid grey',
    minHeight: 38,
    height: 38,
    width: 38,
    minWidth: 38,
    textTransform: 'none',
    '@media(min-width: 600px)': {
      minHeight: 48,
      height: 48,
      width: 175,
      minWidth: 146,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  children: {
    width: '100%',
    height: 'auto',
    scrollBehavior: 'smooth',
    overflow: 'hidden',
  },
  flagContainer: {
    marginRight: 10,
    paddingBottom: 0,
    '@media only screen and (max-width: 600px)': {
      marginTop: -82,
    },
  },
  flagSelectButton: {
    padding: '3px 10px',
  },
  btnBoutique: {
    margin: theme.spacing(1),
    fontSize: '0.9rem',
    '& span': {
      textTransform: 'none !important',
      fontFamily: 'ITC Avant Garde Gothic Std Medium !important',
      fontWeight: 400,
    },
    '& .MuiButton-startIcon': {
      width: '20% !important',
    },
  },
  typography: {
    color: '#0E1247',
  },
  contentBtn: {
    backgroundColor: 'white',
    color: 'black',
    width: '90%',
    margin: '4vh auto',
    display: 'flex',
    justifyContent: 'center',
    padding: '1vh',
  },
  menuItem: {
    margin: '0px !important',
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 30,
  },
  menuList: {
    overflow: 'visible',
    paddingTop: '15px !important',
  },
}));
