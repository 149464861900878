// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { RadioButtonFigure } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding: 20,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding: 20,
    '@media only screen and (max-width: 600px)': {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: 12,
      fontWeight: 800,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: 10,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 10,
      fontSize: 12,
      fontWeight: 800,
    },
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#eaeff9',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
  cardBgGrey: {
    background: '#e8eef4',
  },
  borderTable: {
    borderRadius: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important',
  },
  img: {
    width: '40%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '80%',
    },
  },
  paragraphe: {
    fontSize: '14px',
    color: '#2c57a5',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left !important',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left !important',
    },
  },
  floatIndex: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `url(${imgBook1}/back.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      bottom: '5px',
      left: '5px',
    },
  },
});

const TableFigureChef2 = (props: any) => {
  const classes = useStyles();
  const { head, figures, handleChangeFigure, defaultValue } = props;
  const [figure6, setFigure6] = useState(defaultValue);
  const handleChangeNumber = (data: any) => {
    setFigure6(data.value);
    handleChangeFigure(data.value);
  };

  useEffect(() => {
    setFigure6(defaultValue);
  }, [defaultValue]);

  return (
    <Card
      variant="outlined"
      style={{ borderRadius: 0, border: '1px solid #2b56a5', overflow: 'hidden' }}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div className={classes.borderTable}>
            {head && (
              <Grid container={true} justifyContent="flex-end">
                <Grid
                  item={true}
                  xs={1}
                  style={{ textAlign: 'center', border: '1px solid #2b56a5' }}
                  className={classes.categorieB}
                />
                <Grid
                  item={true}
                  xs={5}
                  style={{ textAlign: 'center', border: '1px solid #2b56a5' }}
                  className={classes.categorieA}>
                  <Typography component="p" className={classes.h7}>
                    LE BESOIN PREMIER
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  xs={6}
                  style={{ textAlign: 'center', border: '1px solid #2b56a5' }}
                  className={classes.categorieA}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                    LES 6 FIGURES DU CHEF
                  </Typography>
                </Grid>
              </Grid>
            )}
            <RadioButtonFigure
              radios={figures}
              onChange={handleChangeNumber}
              name="operator1"
              index={1}
              defaultPropsValue={figure6}
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TableFigureChef2;
