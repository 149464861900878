/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { FC } from 'react';
import useStyles from './style';

interface ISomaire {
  list: any[];
}

const Somaire: FC<ISomaire> = ({ list }) => {
  const classe = useStyles();

  const listSomaire = (data: any[]) => {
    return data.map((item: any, index: number) => (
      <div key={index} className={classe.marginSomaire}>
        <Grid container={true} direction="row" justifyContent="space-between">
          <Grid className={classe.containerSom} item={true} xs={11}>
            <a href={`#page-${item.number}`} className={classe.listSomaine}>
              {item.step && (
                <>
                  <span className={classe.itemStep}>{item.step} : </span>
                  <br />
                </>
              )}
              {item.isBold ? (
                <span>
                  <b>{item.text || ''}</b>
                </span>
              ) : (
                <span>{item.text || ''}</span>
              )}
            </a>
          </Grid>

          {/* <Grid item={true} xs={2}>
            <div className={classe.number}>
              {item.isBold ? <b>{item.number || ''}</b> : <>{item.number || ''}</>}
            </div>
          </Grid> */}
        </Grid>
      </div>
    ));
  };

  return (
    <>
      <div className={classe.title}>SOMMAIRE</div>
      <i className={classe.textItalique}>
        Pour naviguer dans l’ebook, vous pouvez utiliser les titres du sommaire pour vous rendre
        rapidement à la partie voulue.
      </i>
      <div className={classe.trait} />
      {listSomaire(list)}
    </>
  );
};

export default Somaire;
