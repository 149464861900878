/* eslint-disable no-use-before-define */
import { Grid } from '@material-ui/core';
import { Img, Page } from '@orientaction/components';
import useStyles from '../../style';

const Page01 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={1} center={true}>
      <Grid container={true} direction="row" justifyContent="center" alignItems="center">
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <img
              className={classes.imgLogo}
              src="/images/Book1/logo_oa_dark_blue_no_text_under.png"
              alt="logo"
            />
          </Grid>
        </Grid>
        <Grid container={true}>
          <Grid xs={3} className={classes.containerTrait}>
            <div className={classes.trait2} />
          </Grid>
          <Grid xs={6}>
            <p className={classes.underLogo}>LES EXPERTS DU RECRUTEMENT</p>
          </Grid>
          <Grid xs={3} className={classes.containerTrait}>
            <div className={classes.trait2} />
          </Grid>
        </Grid>
      </Grid>

      <Img src="lock.png" alt="lock" style={{ with: '10%', position: 'relative', top: '7vh' }} />

      <div className={classes.textLock}>
        La structure générale, ainsi que les textes, photos, images de ce document sont la propriété
        de Dr Emeric Lebreton. Toute reproduction, totale ou partielle, et toute représentation du
        contenu substantiel de ce document, par quelque procédé que ce soit, sans autorisation
        expresse de Dr Emeric Lebreton, est interdite, et constitue une contrefaçon sanctionnée par
        les articles L.335-2 et suivants du Code de la propriété intellectuelle. Les informations,
        pictogrammes, photographies, images, textes sont protégés par des droits de propriété
        intellectuelle. À ce titre, toute reproduction, représentation, adaptation, traduction et/ou
        modification, partielle ou intégrale de ce document sont interdites. La copie sur support
        papier à usage privé de ces différents objets de droits est autorisée conformément à
        l’article L122-5 du Code de la Propriété Intellectuelle. Leur reproduction partielle ou
        intégrale, sans l’accord préalable et écrit de l’auteur, est strictement interdite.
      </div>
    </Page>
  );
};

export default Page01;
