/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath } from '../../../../utils/constants';

const Page04 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={4}>
      <TitlePage>
        VOUS VENEZ DE CANDIDATER À UNE <br />
        OFFRE D’ORIENTACTION® EMPLOI
      </TitlePage>
      <br />
      <Paragrapth align="justify">Cher(e) candidat(e),</Paragrapth>
      <br />
      <br />
      <br />
      <Paragrapth align="justify">
        Nous sommes ravis de vous accueillir dans le processus de recrutement d’ORIENTACTION®
        EMPLOI, une étape cruciale vers une carrière enrichissante et épanouissante. Cet ebook a été
        conçu spécialement pour vous, afin de vous guider à travers une série d’exercices, de tests
        et de questions soigneusement élaborés. Notre objectif ? S’assurer que votre profil
        correspond parfaitement au poste pour lequel vous avez candidaté.
      </Paragrapth>
      <br />
      <br />
      <br />
      <Paragrapth align="justify">
        Chez ORIENTACTION® EMPLOI, nous croyons fermement en la création de relations
        professionnelles harmonieuses et productives. Pour cela, nous nous engageons à aider les
        candidats, tels que vous et les entreprises à se rencontrer dans un environnement propice au
        succès mutuel. Ce guide est un outil précieux pour atteindre cet objectif, en permettant de
        vérifier les 4 points cardinaux d’une candidature réussie :
      </Paragrapth>
      <div className={classe.containerCompass}>
        <h4 className={classe.titleSeconde} style={{ marginBottom: 30 }}>Les 4 points cardinaux du candidat :</h4>
        <Grid container={true}>
          <Grid item={true} xs={12} sm={6}>
            <img src={`${publicBookPath}/compass.bmp`} alt="photo" className={classe.imgNeedFull} />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <div className={classe.containerListCompass} style={{ paddingLeft: 15 }}>
              <div className={classe.marginListCompass}>
                • Vos besoins :{' '}
                <span style={{ color: 'black' }}>
                  {' '}
                  ce qui donne sens à votre carrière professionnelle,{' '}
                </span>
              </div>
              <div className={classe.marginListCompass}>
                • Vos besoins :{' '}
                <span style={{ color: 'black' }}> ce qui vous motive dans votre métier, </span>
              </div>
              <div className={classe.marginListCompass}>
                • Votre personnalité :{' '}
                <span style={{ color: 'black' }}> votre mode de fonctionnement naturel, </span>
              </div>
              <div className={classe.marginListCompass}>
                • Vos talents :{' '}
                <span style={{ color: 'black' }}>
                  {' '}
                  vos soft skills et vos compétences transversales.{' '}
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};
export default Page04;
