import { Page, Img } from '@orientaction/components';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import useStyles from '../../style';
import { imgBook1 } from '@orientaction/utils';
import Typography from '@material-ui/core/Typography';

const Page00 = (props: any) => {
  const classe = useStyles();

  return (
    <div id={`page-${0}`} style={{ marginBottom: '10px', marginTop: '10px' }}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} xs={12} md={8}>
          <Card className={`${classe.couverturePage} ${classe.pagePadding}`}>
            <div className={classe.textCenterTop}>
              <Typography variant="h6" component="h5" style={{ fontSize: 30 }}>
                EMERIC LEBRETON
              </Typography>
              <Typography className={classe.title} gutterBottom={true}>
                DOCTEUR EN PSYCHOLOGIE
              </Typography>
            </div>
            <div className="position-title-cover-2">
              <div className="">
                <h1 className="header__content-title no-margin-content">
                  <span className="header__content-wrap-2">
                    <span className="header__content-desc header__content bg--gradient">
                      <span className="title-2" style={{ textAlign: 'center' }}>
                        <span>Réussir</span> <br /> <span className="span-votre">votre</span> <br />
                        <span className="span-rec">Recrutement</span>
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
            <div className={classe.trait00} style={{ marginTop: 0, marginBottom: 25 }} />
            <img
              src={`${imgBook1}/recrutement_cover.png`}
              alt="couverture intro"
              className={classe.imgCenter}
            />
            <div>
              <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-justify-content-xs-center">
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7">
                  <div className="book__footer--image bg--gradient--other mb-5">
                    <img
                      className="jss150"
                      src="/images/Book1/logo_oa_dark_blue_no_text_under.png"
                      alt="logo"
                    />
                  </div>
                  <p className="text-center text-blue">LEADER DU BILAN DE COMPÉTENCES</p>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Page00;
