// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, Page, Title } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenterTop: {
      textAlign: 'center',
      color: '#2b56a5',
      fontFamily: 'ITC Avant Garde Gothic Std bold',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontSize: 22,
    },
    cardBgGrey: {
      padding: 20,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  })
);

const Page43 = () => {
  const classes = useStyles();

  return (
    <Page id={43}>
      <div className={classes.cardBgGrey}>
        <Typography className={classes.textCenterTop} gutterBottom={true}>
          LES DÉVELOPPEURS(SES) INFORMATIQUES
        </Typography>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            Un(e) développeur(se) informatique utilise un certain langage pour créer une application
            informatique. Or, les langages évoluent très rapidement.
          </Paragrapth>
        </MarginContainer>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            Les compétences informatiques ou « hard skills » sont donc hautement périssables. Dit
            autrement, plus le temps passe et plus la personne devient incompétente…
          </Paragrapth>
        </MarginContainer>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            Cette situation est vraie dans un grand nombre de métiers, puisque les outils techniques
            sont appelés à devenir obsolètes…
          </Paragrapth>
        </MarginContainer>
        <Paragrapth align="justify" addBackground={true}>
          À l’inverse, les « soft skills » ne se périment pas. Tel un bon vin, elles se bonifient
          avec le temps…
        </Paragrapth>
      </div>
      <MarginContainer>
        <Paragrapth align="justify">
          Ce qui est motivant pour le (la) salarié(e) dans l’acquisition des « soft skills », c’est
          que ces compétences « restent » et qu’elles ne sont pas dépendantes d’un emploi ou d’une
          entreprise donnée.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Les « soft skills » sont la propriété de la personne. À l’instar du patrimoine financier,
          elles constituent un capital que le (la) salarié(e) peut investir pour en tirer un
          résultat, que cela soit en termes de rémunération, de sécurité de l’emploi ou même de
          bonheur…
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Les « soft skills » constituent donc des compétences particulièrement intéressantes à
          acquérir.
        </Paragrapth>
      </MarginContainer>
      <div className={classes.cardBgGrey}>
        <Typography className={classes.textCenterTop} gutterBottom={true}>
          LE POINÇONNEUR DES LILAS
        </Typography>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            Dans le passé existait un métier, celui de poinçonneur(se). Les personnes qui
            l’exerçaient avaient pour tâche de poinçonner les tickets dans les transports publics.
            Dans le cadre de leur métier, elles avaient régulièrement à gérer des conflits (conflits
            entre les voyageurs(ses), voyageurs(ses) sans billet, etc.) ou des situations de
            détresse (personnes perdues, etc.).
          </Paragrapth>
        </MarginContainer>
        <Paragrapth align="justify" addBackground={true}>
          Les poinçonneurs(ses) jouaient un vrai rôle social alliant sécurité et information. À
          travers ce métier, les personnes devaient développer certaines qualités humaines. Leur
          métier disparu, elles n’en ont pas moins conservé ces qualités bien après.
        </Paragrapth>
      </div>
      <MarginContainer>
        <Paragrapth align="justify">
          Autre point important : les tâches dites « techniques » sont aujourd’hui de plus en plus
          souvent assurées par des robots ou des systèmes automatisés (automates, logiciels,
          intelligences artificielles, etc.).
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Les « soft skills » restent pour la plupart hors de portée des robots. Dit autrement, la
          meilleure manière pour un(e) salarié(e) de préserver son emploi consiste à développer ses
          « soft skills ».
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Les « soft skills » sont des qualités personnelles qui transforment un(e) salarié(e)
          lambda en un(e) collaborateur(trice) efficace, performant(e), agréable et qui motive tout
          le reste de son équipe.
        </Paragrapth>
      </MarginContainer>
      <div className={classes.cardBgGrey}>
        <Typography className={classes.textCenterTop} gutterBottom={true}>
          L’ÉVOLUTION DU MÉTIER DE MÉDECIN
        </Typography>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            On sait maintenant que le diagnostic des maladies réalisé à partir de radios ou de
            scanners est plus sûr lorsqu’il est réalisé par une intelligence artificielle que par un
            être humain.
          </Paragrapth>
        </MarginContainer>
        <Paragrapth align="justify" addBackground={true}>
          Est-ce à dire que le métier de médecin va disparaître comme celui de poinçonneur(se) ?
          Loin de là. Le métier va évoluer. Il deviendra bientôt moins un métier de diagnostic
          qu’un métier de relations humaines et d’accompagnement. Le médecin annonce au (à la)
          patient(e) le diagnostic et l’accompagne dans son traitement vers la guérison.
        </Paragrapth>
      </div>
    </Page>
  );
};

export default Page43;
