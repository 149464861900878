/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */

import { AppThunk } from '../store';

export const updateField =
  (field: string, value: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'UPDATE_FIELD_EBOOK_REC',
      payload: { field, value },
    });
  };

export const setAllField =
  (value: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'SET_ALL_FIELD',
      payload: value,
    });
  };