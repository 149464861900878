/* eslint-disable react/destructuring-assignment */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr } from '@orientaction/components';
import Title from './Title';
import useStyles from './style';
import { useBook4 } from '@orientaction/hooks_book';
import { list } from './constant';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { apiServiceNative } from '../../../../../utils/fetchNative';

const Page46 = (props: any) => {
  const classes = useStyles();

  const [softSkillText, setSoftSkillText] = useState<any>([]);
  const [softSkillTag, setSoftSkillTag] = useState<any>([]);
  const ebookRecVal = useSelector(ebookRecValues);
  const { idEbook } = props;

  const handleChange = async (e: any, index: number) => {
    const { name, value } = e.target;
    const dataS = cloneDeep(softSkillText);
    dataS[index] = value;
    setSoftSkillText(dataS);
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        softSkillText: JSON.stringify(dataS),
      },
    });
  };

  useEffect(() => {
    if (ebookRecVal) {
      const groupAdjectifs = [
        ebookRecVal?.softSkill1 || '',
        ebookRecVal?.softSkill2 || '',
        ebookRecVal?.softSkill3 || '',
        ebookRecVal?.softSkill4 || '',
        ebookRecVal?.softSkill5 || '',
      ];
      setSoftSkillTag(groupAdjectifs);
      setSoftSkillText(ebookRecVal?.softSkillText ? JSON.parse(ebookRecVal?.softSkillText || "") : []);
    }
  }, [ebookRecVal]);

  return (
    <Page id={46} readOnly={false}>
      <TitleNbr
        number={3}
        text="Maintenant, illustrez vos « soft skills » avec des expériences
        concrètes : "
      />

      <MarginContainer>
        <Paragrapth align="justify">
          Reliez maintenant chaque « soft skill » à une expérience professionnelle ou personnelle.
          Cette expérience correspond à un moment où vous avez dû faire appel à cette qualité. Par
          exemple : « je suis une personne créative. Chaque jour dans mon travail, je lance de
          nouvelles idées. J’ai notamment lancé l’idée d’une nouvelle organisation plus efficace
          pour le standard téléphonique ». L’objectif est de pouvoir démontrer que vous possédez
          cette « soft skill », qu’elle vous caractérise et que vous saurez faire preuve de cette
          qualité une fois dans l’action.
        </Paragrapth>
      </MarginContainer>

      {list.map((item: any, index: number) => (
        <>
          <MarginContainer>
            <Title content={softSkillTag ? softSkillTag[index] : ''} label={item.title} />
          </MarginContainer>

          <MarginContainer>
            <Paragrapth
              colorBlue={true}
              overrideParagraphStyle={classes.overrideTextStyle}
              align="justify">
              {item.paragraph}
            </Paragrapth>
          </MarginContainer>

          <TextArea
            name={item.id}
            onChange={(e) => handleChange(e, index)}
            value={softSkillText[index]}
            largeTextField={true}
            readOnly={props.readOnly}
          />
        </>
      ))}
    </Page>
  );
};

export default Page46;
