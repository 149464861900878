// eslint-disable-next-line simple-import-sort/imports
import { Page, Somaire2 } from '@orientaction/components';

const Page3 = (props: any) => {
  return (
    <Page id={3}>
      <Somaire2
        list={[
          {
            step: 'ÉTAPE 1',
            text: 'IDENTIFIER CE QUI DONNE DU SENS À VOTRE TRAVAIL',
            isBold: true,
            number: 8,
          },
          {
            step: 'ÉTAPE 2',
            text: 'ANALYSER COMMENT VOTRE TRAVAIL RÉPOND À VOS BESOINS',
            isBold: true,
            number: 19,
          },
          {
            step: 'ÉTAPE 3',
            text: 'MIEUX COMPRENDRE VOTRE PERSONNALITÉ PROFESSIONNELLE',
            isBold: true,
            number: 30,
          },
          {
            step: 'ÉTAPE 4',
            text: 'MIEUX CONNAÎTRE VOS TALENTS (SOFT SKILLS ET HARD SKILLS)',
            number: 41,
          },
          {
            step: 'ÉTAPE 5',
            text: 'DÉTERMINER VOTRE ÉQUILIBRE VIE PRO / VIE PERSO',
            number: 49,
          },
        ]}
      />
    </Page>
  );
};

export default Page3;
